import React from 'react'
import ReactMarkdown from 'react-markdown'


import TopicHeader from '../hoc/topic-header'
import {dummyMarkdown} from '../defaults/default-data'

import './text-block-display.css'

const TextBlockDisplay  = ({
    className,
    text = dummyMarkdown(),
    showHeading
}) =>{

// https://ghost.org/blog/markdown/
    /*
    TODO:
    - Some logic to convert time and timezones
    - Some logic to handle styling
    - Some logic to handle positioning

    */

    // const fontSize = props.fontSize
    // const className = props.className ? props.className : ''
    // const text = props.text
    // const showHeading = props.showHeading || false
    
    const sampleMarkdown = '    '
    var string = '' + text
    
    return (
        <div className={className}>
            { showHeading && <TopicHeader heading={'Details'} subheading={''} className=''/> }
            <ReactMarkdown className='markdown-content TextBlockDisplayContent'>{string}</ReactMarkdown>
        </div>
    )

}


export default TextBlockDisplay

//<p className={''}>{text}</p>