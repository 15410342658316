

export const regions = [
    {value: 0, enabled: true, region: 'South-East Queensland'},
    {value: 1, enabled: true, region: 'Central Queensland'},
    {value: 2, enabled: true, region: 'North Queensland'},
    {value: 3, enabled: true, region: 'Far-North Queensland'},
    {value: 4, enabled: true, region: 'South Australia'},
    {value: 5, enabled: true, region: 'Western Australia'},
    {value: 6, enabled: false, region: 'Tasmania'},
    {value: 7, enabled: false, region: 'New South Wales'},
    {value: 8, enabled: false, region: 'Australian Capital Territory'},
    {value: 9, enabled: false, region: 'Northern Territory'},
    {value: 10, enabled: false, region: 'Victoria'},
    {value: 'ALL', region: 'Australia'}

]

