const firebaseDev = {
    apiKey: "AIzaSyDgYeHG0SKfjkq-KgrKfiGAKbEIRAu4kHE",
    authDomain: "softops-dev-6c50b.firebaseapp.com",
    databaseURL: "https://softops-dev-6c50b.firebaseio.com",
    projectId: "softops-dev-6c50b",
    storageBucket: "softops-dev-6c50b.appspot.com",
    messagingSenderId: "120840342819",
    appId: "1:120840342819:web:b1f96e9ae60a7520785096",
    measurementId: "G-GT1KN1NVBJ"
    };


const firebaseProd = {
    apiKey: "AIzaSyDVHJF3X1-cRq76lH_NXrmulbWNO4ExH4A",
    authDomain: "softops-app.firebaseapp.com",
    databaseURL: "https://softops-app.firebaseio.com",
    projectId: "softops-app",
    storageBucket: "softops-app.appspot.com",
    messagingSenderId: "536068959972",
    appId: "1:536068959972:web:1d66596e93d852ff",
    measurementId: "G-P6K4SJWK50"
}

export const firebaseConfig = process.env.REACT_APP_STAGING === 'prod'
    ? firebaseProd
    : firebaseDev


// export default {
//     //...other config values
//     firebaseConfig
// }