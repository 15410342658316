import React, {useContext} from 'react'
import { SystemNotificationsContext } from '../../context/SystemNotifications'

import './system-notifications.css'

const SystemNotifications = () =>{

    const { currentSystemNotifications } = useContext( SystemNotificationsContext )

    const compatible = 'serviceWorker' in navigator    
    if (!compatible) {
        return null
    }

    navigator.serviceWorker.addEventListener('controllerchange', () => {
        // This fires when the service worker controlling this page
        // changes, eg a new worker has skipped waiting and become
        // the new active worker.
        window.location.reload()
      });



    const onClick = (e) => {
        e.persist()
        console.log(e)
        const tgt = document.getElementById('systemnotification-0')
        tgt.classList.add('clickedWaiting')
        forceRefresh()
    }

    // if ('serviceWorker' in navigator) {
    //     // console.log('SW in Nav')
    // } else {
    //     console.log('No SW')    
    // }


    return (
        <div className='SystemNotificationsContainer'>
            { currentSystemNotifications.map((note, index) => 
                <button className='SystemNotification' key={index} id={`systemnotification-0`} onClick={function(e){onClick(e)}}>
                    <span className='SystemNotificationContent' >
                        {//<i className='SystemNotificationIcon ri-alert-line'/>
                        }
                        <span className='SystemNotificationMessage'>{note}</span>
                        <span className='SystemNotificationAction'>Reload App</span>
                        </span>
                </button>
            )}
        </div>
    )

}


const forceRefresh = async () => {
    // this is the way https://github.com/facebook/create-react-app/issues/5316#issuecomment-539260745


    const waitingServiceWorker = await navigator.serviceWorker.ready
    
    if (waitingServiceWorker.waiting) {
        waitingServiceWorker.waiting.postMessage({type: "SKIP_WAITING"});
    }


    // 
}



export { SystemNotifications }


