import React from 'react'

import '../hoc/loading-display.css'
import { ReactComponent as Icon} from '../assets/icons/mi-softops.svg'

const LoadingDisplay = ({
    loadingMessage = 'Retrieving Data',
    ...restProps
}) =>{

    return (
        <div className='LoadingDisplayContainer'>
            { 
                <div className='LoadingDisplayText'>
                    <div className='LoadingImage'>
                        <Icon />
                       



                    </div>
                    <div className='LoadingDisplayString'>
                        {loadingMessage}
                    </div>
                
                </div>
            }
        </div>
    )

}

export { LoadingDisplay }




