import React from 'react'
import { Link } from 'react-router-dom'

import './button-text.css'

export const ButtonText = ({
    children,
    to,
    cta = false,
    label,
    className =  '',
    clickType = '',
    onClick,
    propHandleClick = function(){},
    icon = '',

    external = false,
    ...restProps
}) =>{
    const contents = 
                <div className='ButtonTextLabelContainer'>
                            {icon && <i className={`${icon} ButtonTextIcon`}/>}
                            {label || children ? <span className='ButtonTextLabel'>{label || children}</span> : null }
                </div>
                

    // External links need a href handler
    if (external) {
        return (
            <a href={to} target='_blank' className={`${className} ButtonText`}  
            onClick={(event) => {}}>
                    {contents}
            </a>
            )}                
    // Internal links need a router link
    if (to) {
        return (
            <Link to={to} className={`${className} ButtonText`}  
            onClick={(event) => {}}>
                    {contents}
            </Link>
            )}    
    
    // Anything else should be a function based button
    return (
            <button className={`${className} ${cta ? 'cta' : ''} ButtonText`}  
            onClick={(event) => {onClick && onClick(event)}}>
                    {contents}
            </button>
    )

}

