import React, {useContext, useEffect} from 'react'

import { Helmet } from 'react-helmet'

import { UIContext } from '../../context/UI'

import ScrollToTop from '../../hoc/scroll-to-top'


import './longform.css'

const LongformViewComponent = ({
    content = null,
    titlebar = '',
    ...restProps

}) => {

    const { setAutoNavbar, setShowBack, setShowLegal, setModalActive }            = useContext(UIContext)

    useEffect(()=> {
        setAutoNavbar(true)
        setShowBack(true)
        setShowLegal(false)
        setModalActive(false)
    },[])
    

    // Otherwise, show the article
    return(
        <div className='LongformViewComponent'>
            <Helmet>
                <title>{`SOFTOPS | ${titlebar}`}</title>
            </Helmet>
            <ScrollToTop/>
            <div className='content-container'>
                <div className='content-wrapper offset-navbar '>
                    
                <div className='content-container content'>
                    <div className='LongformBody'>
                    {content}
                    
                    </div>
                </div>
                </div>
                
            </div>
        </div>
    )
}

export { LongformViewComponent  }

// <i className='ri-heart-line'/>
// <i className='ri-share-forward-line'/>