import React from 'react'


const TagListDisplay = (props) =>{

    /*
    TODO:
    - Some logic to convert time and timezones
    - Some logic to handle styling
    - Some logic to handle positioning

    */

    // const fontSize = props.fontSize

    const className = props.className !== undefined ? props.className : ''
    const tags = props.tags
    const displayMode = props.displayMode


    switch (displayMode) {
        case 'list':
            return (
                <div
                    className = {`font-alt small medium upper spacing-medium ${className}`}>
                    { tags !== undefined ?
                    <ul className='list-plain'>
                        {tags.map(tag => 
                            tag !== null && tag.label !== '' ? 
                            <li key={tag.label} className={`inline-block tag ${tag.type}`}>{tag.label}</li> 
                            : null
                        )}
        
                    </ul> : null }
                </div>
            )
        case 'line':
            return (
                <div>
                    {'A single line of values...'}
                </div>
            )
    
        default:
            return (
                <div>
                    {'A single line of values...'}
                </div>
            )

    } 


}


export default TagListDisplay

