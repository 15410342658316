import React from 'react'


import {getRelativeTime} from '../../hoc/getRelativeTime'

import { TileListItem } from '../../hoc/tile'
import { ButtonText } from '../../hoc/button'
import { YoutubeViewer } from '../YoutubeViewer'
import { WebVideoViewer } from '../WebVideoViewer'

import './article-stub.css'


const ArticleStub = ({
    pinned,
    children,
    datePublished,
    dateUpdated,
    headline,
    subhead,
    img,
    youtube,
    webvideo,
    slug,
    buttontext,
    buttonlink,
    buttonexternal, 
    type = 'post', // [ post, ad, video, longform ]
    ...restProps
}) => {

    // Some logic to determine the format
    // style={ {background:'var(--main-accent-color)'}} // this can be appended to node and inherit styles

    return (
        <TileListItem display='wide' className='ArticleStub' >

            <h2>{headline}</h2>
            { pinned ? <div className='icon-pin'>📌</div> : false }
            { subhead ? <h3><span>{subhead}</span></h3> : null }
            { datePublished ? <div className='published-date'>{ dateUpdated ? `Updated ${getRelativeTime(dateUpdated)}` : `Posted ${getRelativeTime(datePublished)}` }</div> : null }
            { img ? <img src={img} className='placeholder-img' /> : null }
            { youtube ? <YoutubeViewer youtubeId={youtube} className='video-content'/>: null }
            { webvideo ? <WebVideoViewer className='video-content' src={webvideo}/> : null }
            {children}
            <p className='slug'>{slug}</p>    
            { buttontext ? 
                <ButtonText to={buttonlink} external={ buttonexternal ? true : false}>{buttontext}</ButtonText> : null }

        </TileListItem>
    )
}

export {
    ArticleStub
}




