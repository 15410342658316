import React, {useEffect, useState} from 'react'
// import {AuthContext} from './Auth' // Not yet implemented, will use to check currentUser.

export const UserContext = React.createContext()

export const UserProvider = ({ children }) => {

    const preferencesDefaults = {
        region: null,
        acceptedTerms: null,
        seenWelcome: null,
    }

    const [ preferences, setPreferences] = useState(JSON.parse(localStorage.getItem('userPreferences')) || preferencesDefaults)
    const [ viewNeedsPreferences, setViewNeedsPreferences ] = useState(false)
    

    useEffect(()=> {
        localStorage.setItem('userPreferences', JSON.stringify({...preferences}))
    }, [preferences])


    return (
        <UserContext.Provider
        value={{
            preferences, setPreferences,
            viewNeedsPreferences, setViewNeedsPreferences
        }}
        >
        {children}
        </UserContext.Provider>
            
    )
}

