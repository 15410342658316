import React, {useState, useContext, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { Redirect } from 'react-router'

import { firebaseAnalytics } from '../../firebase'


import { getRelativeTime } from '../../hoc/getRelativeTime'
import Moment from 'moment'


import { UIContext } from '../../context/UI'
import { DataContext } from '../../context/Data'


import { ViewHeader } from '../ViewHeader'
import { ButtonText } from '../../hoc/button'
import { YoutubeViewer } from '../YoutubeViewer'
import { WebVideoViewer } from '../WebVideoViewer'
import {LoadingDisplay} from '../../hoc/loading-display'
import TextBlockDisplay from '../../hoc/text-block-display'
import ScrollToTop from '../../hoc/scroll-to-top'


import './article-view.css'
import { getSingleArticle } from '../../services/DataService'


const ArticleViewComponent = (props) => {

    const [article, setArticle ] =  useState()
    const [notFound, setNotFound] = useState(false)


    const { currentArticleData, currentPinnedData, singleNewsHistory }         = useContext(DataContext)

    const id = props.location.pathname.replace('/article/','')
    var ref = queryString.parse(useLocation().search).ref === 'external' || false

    const { setAutoNavbar, setShowBack, setShowNavbar }     = useContext(UIContext)


    useEffect(()=> {
        setAutoNavbar(true)
        setShowBack(true)
        setShowNavbar(true)

        // Check we don't have an article, and we haven't attempted to retrieve it

        if(!article && !notFound){
            const article = currentArticleData.data.find(article => {
                return article.id === id
            })

            const pinnedArticle = currentPinnedData.data.find(article => {
                return article.id === id
            })

            const noResult = singleNewsHistory.find(function(article){
                return article = id
            })

            if(article) { setArticle(article) }
            else if(pinnedArticle) { setArticle(pinnedArticle) }                       // Found an article
            else if (noResult) { setNotFound(true)}                 // Have searched, found nothing
            else if (currentArticleData.status.fetching !== true) { // Has not search, try search
                getSingleArticle(id)
            }
        }


    },[currentArticleData])
    



    useEffect(() => {
        // As long as an article is found, send an analytics event
        firebaseAnalytics.logEvent('view_news_article')

    }, [article])




    // If there is no article, show the load screen
    if (!article) { return <LoadingDisplay/> }


    // If the article isn't found, redirect to the news page
    if (notFound) {
        return (
            <Redirect push to='/news'/>
        )
    }

    // Otherwise, show the article
    return(
        <div className='ArticleViewComponent'>
            <Helmet>
                <title>{`SOFTOPS | ${article.headline}`}</title>
            </Helmet>
            <ViewHeader/>
            <ScrollToTop/>
            <div className='content-container'>
                <div className='content-wrapper offset-navbar'>
                    <div className='content-container content'>
                        { article.img ? <img src={article.img} className='ArticleTopImg' /> : null }
                        { article.youtube ? <YoutubeViewer youtubeId={article.youtube} className='ArticleTopVideo'/>: null }
                        { article.webvideo ? <WebVideoViewer className='ArticleTopVideo' src={article.webvideo}/> : null }
                        <div className='ArticleViewBody'>

                            <h1>{article.headline}</h1>
                            <div className='ArticleInfoBar'>
                                <h3>{article.subhead}</h3>
                                <div className='ArticleSocial'>

                                </div>
                                <div className='ArticleTimestamp'>{ article.dateUpdated ? `Updated ${getRelativeTime(article.dateUpdated)}` : `Posted ${getRelativeTime(article.datePublished)}` }</div>
                            </div>
                            { article.actionlocations && article.actionlocations.top &&
                                    <div className='ArticleActions'>
                                    { article.actionbuttons.map((button, idx) => <ButtonText key={`button-${idx}`} icon={button.icon} to={button.to} external={button.external} className={button.cta ? 'cta':''}>{button.text}</ButtonText>)}
                                    </div> }
                            <TextBlockDisplay className='ArticleMarkdown' text={article.body} /> 

                            { article.actionlocations && article.actionlocations.bottom &&
                                <div className='ArticleActions'>
                                { article.actionbuttons.map((button, idx) => <ButtonText key={`button-${idx}`} icon={button.icon} to={button.to} external={button.external} className={button.cta ? 'cta':''}>{button.text}</ButtonText>)}
                                </div> }   
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export { ArticleViewComponent  }

// <i className='ri-heart-line'/>
// <i className='ri-share-forward-line'/>