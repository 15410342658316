import React from 'react'

import whitesquare from '../../assets/backgrounds/whitesquare.png'

import { ReactComponent as Characters} from '../../assets/backgrounds/chars-1.svg'
import { ReactComponent as FgTrees} from '../../assets/backgrounds/trees-fg.svg'
import { ReactComponent as BgTrees} from '../../assets/backgrounds/trees-bg.svg'

import './event-background.css'

const EventBackground = ({promoted=false, ...restprops}) => {

    return (
    <div className= {`parallaxContainer ${promoted ? 'promoted' : ''}`} id='bg-container' draggable={false}>
        <img className= 'EventViewHeaderBackground-block' src={whitesquare} draggable={false} nopin='nopin'/>
        <BgTrees className= 'EventViewHeaderBackground-0' draggable={false} nopin='nopin' />
        <FgTrees className= 'EventViewHeaderBackground-1' draggable={false} nopin='nopin'/>
        <Characters className= 'EventViewHeaderBackground-2' draggable={false} nopin='nopin'/>

    </div>
    )
}

export {EventBackground}


// <img className= 'EventViewHeaderBackground-0' src={image0} draggable={false} nopin='nopin'/>
// <img className= 'EventViewHeaderBackground-1' src={image1} draggable={false} nopin='nopin'/>
// <img className= 'EventViewHeaderBackground-2' src={image2} draggable={false} nopin='nopin'/> 