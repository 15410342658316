import React, {useState, useContext, useEffect, Fragment} from 'react'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { firebaseAnalytics } from '../../firebase'
import {Helmet} from 'react-helmet'
import ButtonInstallApp from '../../hoc/button-installapp'

import { getNewsFeed } from '../../services/DataService'
import { UIContext } from '../../context/UI'
import { DataContext } from '../../context/Data'

import {ViewHeader} from '../ViewHeader'
import TopicHeader from '../../hoc/topic-header'
import { LoadingDisplay } from '../../hoc/loading-display' 

import { TileListItem } from '../../hoc/tile'
import { ButtonText } from '../../hoc/button'

import { LazyLoad } from '../LazyLoad'

import { ArticleStub } from '../Article'
import ScrollToTop from '../../hoc/scroll-to-top'

import './home-component.css'


const HomeComponent = (props) => {

    const {setAutoNavbar, setShowBack, setShowNavbar} = useContext(UIContext)
    const { currentArticleData, currentPinnedData, currentNewsPage, setNewsPage, newsEmptyFetchCount } = useContext(DataContext)

    const [analyticsSent, setAnalyticsSent] = useState(false)
    var scroll = queryString.parse(useLocation().search).pos === 'top' || false

    const onLoad = () => {

        setAutoNavbar(false)
        setShowBack(false)
        setShowNavbar(true)
    }
 
    useEffect(()=> {
        onLoad()

        window.history.replaceState(null, null, 'news') // remove any refs to scroll
        // Onload, send an analytics event
        if (!analyticsSent) {
            firebaseAnalytics.logEvent('view_news') 
            setAnalyticsSent(true)
        } 

        
    },[])



    // If data is being retrieved, just return the loading screen

    
    // insert LazyLoad to article list

    return(
        <div className='HomeViewComponent'>
            <Helmet>
                <title>{`SOFTOPS | Gel Ball News`}</title>
            </Helmet>
            { scroll ? <ScrollToTop/> : null }
            <ViewHeader/>
            <div className='content-container'>
                <div className='content-wrapper offset-navbar'>
                    <div className='content-container content'>
                    <ButtonInstallApp/>
               
                        <Fragment>
                        {[...currentPinnedData.data].map((slug, idx) => 
                            <ArticleStub key={idx} {...slug}/>)}
                        </Fragment>
                        
                    

                    { currentArticleData.status.fetching && currentArticleData.data.length === 0
                        
                        
                        ? 
                            <LoadingDisplay/> 
                            : 
                            <Fragment>{[...currentArticleData.data].map((slug, idx) => 
                            <ArticleStub key={idx} {...slug}/>)}
                            <TileListItem> 
                            <LazyLoad   lead={20}
                                        status={currentArticleData.status.fetching}
                                        onVisible={
                                                newsEmptyFetchCount < 3  && currentArticleData.data.length > 0 ? 
                                                        function(){ setNewsPage(currentNewsPage => { return (currentNewsPage += 1) })}
                                                    :   function(){} }
                                        onNotVisible={ function(){/*Empty function, do nothing*/} }/>
                        </TileListItem>       
                            </Fragment>
                    }
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export { HomeComponent  }