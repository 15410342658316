import React, {useContext, useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'
import {Helmet} from 'react-helmet'
import queryString from 'query-string'
import { Redirect } from 'react-router'

import { firebaseAnalytics } from '../../firebase'

import { DataContext } from '../../context/Data'
import { UIContext } from '../../context/UI'
import { getEventSingleData } from '../../services/DataService'

// content components
import EventViewHeader from './event-view-header'
import DateTimeDisplay from '../../hoc/datetime-display'
import AddressDisplay from '../../hoc/address-display'
import TextBlockDisplay from '../../hoc/text-block-display'
import PackageDisplay from '../../hoc/package-display'
import {LoadingDisplay} from '../../hoc/loading-display'
import Disclaimer from '../disclaimer'

import { HorizontalDisplay } from '../Shelves'

import { getGoogleMapsURL } from '../../hoc/url-google-maps'

import ScrollToTop from '../../hoc/scroll-to-top'

import './event-view-component.css'
import { ButtonText } from '../../hoc/button'




const EventViewComponent = (props) => {

    const [currentEvent, setEvent] = useState(null)
    const [currentPackages, setPackages] = useState([])
    const [notFound, setNotFound] = useState(false)

    // const id = props.match.params.eventid
    const id = props.location.pathname.replace('/event/','')
    const { currentEventData, singleHistory } = useContext( DataContext )
    const { setShowBack, setAutoNavbar, setShowNavbar } = useContext(UIContext)
    
    // const event = currentEventData.data.find(function(event) {
    //     return event.id === id
    // })

    var ref = queryString.parse(useLocation().search).ref === 'external' || false
    var promoted
    var featured


    const findEvent = (id) => {
        if (!currentEvent && !notFound){
            const event = currentEventData.data.find(function(event) {
                return event.id === id
            })

            const noResult = singleHistory.find(function(event){
                return event = id
            })

            if (event) { setEvent(event) } 
            else if (noResult) { setNotFound(true)  }
            else if (currentEventData.status.fetching !== true) {
                
                getEventSingleData(id)
            }
        }
    }


    useEffect(() => {
        setShowBack(true)
        setAutoNavbar(true)
        setShowNavbar(true)
       
    },[])


    useEffect(() => {


        
    }, [currentEventData])
    

    useEffect(() => {
        // Onload, send an analytics event
        if (currentEvent) {
            firebaseAnalytics.logEvent('view_event', {id: currentEvent.id})

            setPackages([
                ...currentEvent.payment.packages.filter(paymentPackage => paymentPackage.type === 'entry'),
                ...currentEvent.payment.packages.filter(paymentPackage => paymentPackage.type === 'hire'),
                ...currentEvent.payment.packages.filter(paymentPackage => paymentPackage.type === 'other')
            ]
            )

            promoted = currentEvent.promotion.applies
            featured = currentEvent.promotion.featured
        }

        
    }, [currentEvent])

    // useEffect(() => {
    //     currentEventData.data.length > 0 &&
    //     setPackages([
    //         ...event.payment.packages.filter(paymentPackage => paymentPackage.type === 'entry'),
    //         ...event.payment.packages.filter(paymentPackage => paymentPackage.type === 'hire'),
    //         ...event.payment.packages.filter(paymentPackage => paymentPackage.type === 'other')
    //     ]
    //     )
    // }, [currentEventData])


    if (notFound) {
        return(
            <Redirect push to='/events?view=featured'/>
        )
    }

    if (    
            // !currentEventData.status.attempted 
            // && currentEventData.status.outcome !== 'success'
            // && 
            !currentEvent ) {
        findEvent(id)
        return(

            <LoadingDisplay/>

        )
    } 



  
    return(
        <div className={`EventViewComponent ${currentEvent.promotion.applies ? 'promoted' : ''}`}>
            <Helmet>
                <title>{`SOFTOPS | ${currentEvent.operator.name} - ${currentEvent.name}`}</title>
                <meta property="og:image" content="https://i.ytimg.com/vi/_jNkr8YsAQg/maxresdefault.jpg" />
            </Helmet>
            <ScrollToTop/>
            <EventViewHeader
                eventData= {currentEvent}
                extRef={ref}
                promoted={currentEvent.promotion.applies}
            />
    
        
            <div className='content-wrapper' id='event-list-partition'>
                <div className='content-container auto-rows-42 EventViewContent'>

                    <DateTimeDisplay
                        cancelled={currentEvent.status.toLowerCase() === 'cancelled'}
                        dateStart={currentEvent.date.start} 
                        timeStart={currentEvent.time.start} 
                        timeEnd={currentEvent.time.end}
                        />

                    <AddressDisplay
                        locationData={currentEvent.location}
                    />

                    { (currentEvent.about !== undefined && currentEvent.about.length > 0) || false ? 
                        <TextBlockDisplay
                            grid={{gridRow: 4, gridCol: ' 2 / span 10'}}
                            heading={'About this event'}
                            className='EventDetailsTextBlock'
                            text={currentEvent.about}
                            />
                        : null
                    }


                    <div className='BtnContainer'>
                    { 
                        // currentEvent.operator.data.contact.facebook ? 
                        // <ButtonText
                        // icon='ri-facebook-fill'
                        // className='BtnOpenWeb'
                        // clickType='URL'
                        // onClick= { function(){
                        //         window.open( currentEvent.operator.data.contact.facebook , '_blank') 
                        //                                     }}
                        // >
                        
                        // </ButtonText>
                        // : null
                    }
                        <ButtonText
                        icon='ri-map-pin-2-fill'
                        className='BtnOpenMap'
                        clickType='URL'
                        onClick= { function(){
                                window.open(getGoogleMapsURL(
                                                            {   lat:    currentEvent.location.data.coords.lat, 
                                                                long:   currentEvent.location.data.coords.long}), '_blank') 
                                                            }}
                            
                            >
                        Maps
                        </ButtonText>
                    { currentEvent.externalUrl ? 
                        <ButtonText
                        label='Book now'
                        icon='ri-calendar-event-fill'
                        className='BtnOpenWeb'
                        clickType='URL'
                        cta
                        onClick= { function(){
                                window.open( currentEvent.externalUrl , '_blank') 
                                                            }}
                        /> : null
                    
                    }


                    </div>


                    { currentPackages.length > 0 ?
                        <PackageDisplay
                        data={currentEvent.payment}
                        packages={[...currentPackages]}
                    />
                    : null}

                    {
                    // <HorizontalDisplay
                    //     heading='Goodies'
                    //     subheading='Grab what you need to play'
                    
                    // />    
                    }
                    <Disclaimer
                        className='EventViewDisclaimer'/>

                </div>           
            </div>
        </div>
    )
}

export { EventViewComponent }