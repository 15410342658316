import React, {Component} from 'react'


class TrackVisibility extends Component {
    ref = React.createRef()

    async componentDidMount() {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.intersectionRatio === 1) {
                    // the item is full in view
                    console.log('Item is visible')
                    

                } else {
                    console.log(entry.intersectionRatio)
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
            }
        )

        if (this.ref.current) {
            observer.observe(this.ref.current)
        }
    }

    render() {
        return <div ref={this.ref} className='content-wrapper offset-navbar'>{this.props.children}</div>
    }

}


export { TrackVisibility }