import React, { useState } from 'react'
import { ArticleStub } from '../components/Article'

import './button-installapp.css'

const ButtonInstallApp = () => {

    const [ installVisible, setInstallVisible ] = useState(false)
    const [ appInstalled, setAppInstalled ] = useState(false)
    const [ deferredPrompt, setDeferredPrompt ] = useState(null)

    window.addEventListener('beforeinstallprompt', (e) => {
        // Stash the event so it can be triggered later
        e.preventDefault()
        setDeferredPrompt(e)
        setInstallVisible(true)
    })

    window.addEventListener('appinstalled', (e) => {
        console.log('A2HS installed')
        setAppInstalled(true)


        window.removeEventListener('beforeinstallprompt', (e) => {
            // Stash the event so it can be triggered later
            e.preventDefault()
            setDeferredPrompt(e)
            setInstallVisible(true)
        })
    })


    const installAppArticle = {     headline: 'Install App?',
                                    subhead: '',  
                                    slug: 'Get Gel Blaster news and events easily by installing this app to your homescreen!', 
                                    }

    const onClick = () => {
        deferredPrompt.prompt()

        deferredPrompt.userChoice
            .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            setDeferredPrompt(null)
    });
    }


    // Check if the app was launched in standalone mode - if so, show nothing
    if (!appInstalled && window.matchMedia('(display-mode: standalone)').matches) {
        console.log('display-mode is standalone');
        setAppInstalled(true)
    }
    
    
    // If not, show the install button
    // TODO: launch install banner
    if (!installVisible || appInstalled ) { return (null) }

    return(
        <ArticleStub {...installAppArticle}>
            <button className='button-install-app button-link action' onClick={onClick}>
                <div className='ButtonTextLabelContainer'>
                    <i className={`ri-download-line ButtonTextIcon`}/>
                    <span className='ButtonTextLabel'>Install App</span>
                </div>
            </button>
        </ArticleStub>        

        )

}

export default ButtonInstallApp




