import React from 'react'
import Moment from 'moment'

import TopicHeader from '../hoc/topic-header'
import {ButtonText} from './button'


import GameEntry from '../assets/products/bag-game-entry.png'
import GameEntryGroup from '../assets/products/bag-game-entry-group.png'
import GameEntryMulti from '../assets/products/bag-game-entry-multi.png'
import HireStandard from '../assets/products/bag-hire-standard.png'
import HirePremium from '../assets/products/bag-hire-upgraded.png'
import Extras from '../assets/products/bag-extras.png'
import GelsUnlimited from '../assets/products/bag-gels-unlimited.png'



import styles from '../hoc/package-display.css'

const PackageDisplay = (props) =>{

    /*
    TODO:
    - Some logic to convert time and timezones
    - Some logic to handle styling
    - Some logic to handle positioning

    */


    const data = props.data
    const pricepacks = props.packages
    const assets = {
        entry: GameEntry,
        hire: HireStandard,
        other: Extras
    }

    const images = [ 
        GameEntry,
        GameEntryGroup,
        GameEntryMulti,
        HireStandard,
        HirePremium,
        Extras,
        GelsUnlimited,
    ]

    const getPaymentDetails = (data) => {
        let paymentMethods, paymentLocation, paymentBooking 

        switch (data.details.methods) {
            case 'cash':
                paymentMethods = 'with Cash'
                break;
            case 'card':
                paymentMethods = 'with Card'
                break;
            case 'both':
                paymentMethods = 'with Cash or Card'
            break;
            default:
                paymentMethods = ''
            break;
        }

        switch (data.details.where) {
            case 'online':
                paymentLocation = 'Online'
            break;

            case 'onsite':
                paymentLocation = 'on Arrival'
            break;

            case 'both':
                paymentLocation = 'Online or on Arrival'
            break;

            default:
                paymentLocation = 'on Arrival'
            break;
        }

        switch (data.details.booking) {
            case 'prepaid':
                paymentBooking = 'Prepay'
            break;
        
            case 'booking':
                paymentBooking = 'Book'
            break;

            case 'dropin':
                paymentBooking = 'Pay'
            break;

            default:
                paymentBooking = 'Pay'
            break;
        }

        return `${paymentBooking} ${paymentLocation} ${paymentMethods}`


    }


    return (
        <div 
            className='PackageDisplayContainer'>
            
            <TopicHeader
                heading={'Pricing'}
                subheading={''
                    //getPaymentDetails(data)
                }
            />

            <div className='PackageDisplayCardsHorizontal wide'>
            {pricepacks.map( pricepack =>
                <div 
                    className='PackageItemContainer'
                    key={pricepack.id}>
                    <div className='product-shadow'></div>
                    <img    className={'PackageItemImage product-small '} 
                            src={images[pricepack.imageId] ||  assets[pricepack.type]}
                        />

                    <div className='PackageItemValue font-technical large light spacing-large '>
                        {pricepack.price > 0 ? `$${parseFloat(pricepack.price).toFixed(2)}` : ''}
                    </div>

                    <div className='PackageItemName font-alt small medium-w upper spacing-medium accent'>{pricepack.name}</div>     
                </div>)}
            </div>




        </div>
    )

}


export default PackageDisplay
