import React, {useContext, useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { TrackVisibility } from '../../hoc/track-visibility'

import moment from 'moment'

import EventListItem from './event-list-item'
import TopicHeader from '../../hoc/topic-header'
import { LoadingDisplay } from '../../hoc/loading-display'
import { getRelativeTime } from '../../hoc/getRelativeTime'
import { LazyLoad } from '../LazyLoad'

import { TileListItem } from '../../hoc/tile'


import ListCardAction from '../list-card-action'

import { DataContext } from '../../context/Data'

import './event-list.css'

const EventList = (props) => {

    const [viewMode, setViewMode] = useState(props.view.type)
    const [fetchMore, setFetchMore] = useState(true)

    const { currentEventData,
            filteredEventData,
            currentDataPage,
            emptyFetchCount,
            setDataPage } = useContext( DataContext )

    // const { currentDates } = useContext ( DataContext )
    let viewData = []
    let promotedEvents = []
    let dates = []
    
    const view = props.view
    // console.log(view)

    // const eventData = currentEventData.data
    const dataStatus = currentEventData.status
 

    // Logic that determines which events to show
    switch (view.type) {
        case 'featured':
            viewData = filteredEventData.filter(function(item) {
                return item.promotion.applies
            })    

            break;
        
        // case 'date':
        //     dateFilteredData = currentData.filter(function(item) {
        //         // TODO: repurpose
        //         return true
        //         return moment(item.data.date.start.toDate()).isSame(view.value, 'day')
            
        //     })    
        //     break;
        case 'all':
            viewData = filteredEventData.filter(function(item) {
                return true
            })
            
            promotedEvents = filteredEventData.filter(function(item) {
                const isFeatured = item.promotion.featured
                var isValidDate = false
                var currentDate = moment().startOf('d')

                

                if (isFeatured) {
                    isValidDate = currentDate.isBetween(moment(item.promotion.featuredUTCStart.toDate()), moment(item.promotion.featuredUTCEnd.toDate()), 'days', '[]' )
                }

                

                return isFeatured && isValidDate
            })    
            break;

        case 'followed':
            // TODO: Logic for retrieving the user's follow list and only showing those fields
            viewData = filteredEventData.filter(function(item) {
                return false
            })  
            break;

        default:
            viewData = filteredEventData.filter(function(item) {
                return true
            })    
            break;
    }



    // TODO: Clean this up

    // console.log(viewData)

    if (viewData.length > 0 ){
        // Get ALL dates
        viewData.forEach(event => {
            dates.push(moment(event.date.start.toDate()).format())
        })

        dates = [...new Set(dates)]
    }

    // Returns event data filtered by a particular date
    const getEventsByDate = (date, events) => {
        let dateFilteredEvents = events.filter( event =>
            moment(event.date.start.toDate()).isSame(moment(date))
        )
        return dateFilteredEvents
    } 


    // If data is being retrieved, just return the loading screen
    if (dataStatus.fetching && filteredEventData.length == 0) {
        return (
            <div  className='content-wrapper EventListContent'>
                <LoadingDisplay/>
            </div>
        )
    }



    return(


        <div  className='content-wrapper EventListContent'>          
            { 
            //     promotedEvents.length > 0 ? 
            //     <div className='EventListDateSection EventListFeaturedSection'>
            //     <div className='content-container '>
            //         <TopicHeader    heading={'Featured'}
            //                         subheading={'Promoted events'}
            //                         inverted={false}
            //                         className='EventListDateHeader'/>
            //     </div>
            //     {   // We use an array to ensure no doubling up occurs
            //         [promotedEvents[0]].map((e, index) => 
            //         // Return an EventListItem for each Event found
            //             <EventListItem  transitionDelay={index}
            //                             key={e.id} 
            //                             data={e}
            //                             featured={true}/>
            //         )}
            //     </div>
            // : null 
        }



            
            {   // Do we have any dates?
                // true
                // ! dataStatus.fetching &&
                dates.length > 0 
                ?

                // Return a container and heading the content of each Date
                dates.map(date => 
                    <div key={moment(date).format('ddddDMMM')} className='EventListDateSection'>
                        <div className='content-container '>
                            <TopicHeader    heading={moment(date).format('dddd, D MMM')}
                                            subheading={getRelativeTime(moment(date))}
                                            inverted={true}
                                            className='EventListDateHeader'/>
                        </div>


                        {   // Filter the data to each date
                            getEventsByDate(date, viewData)
                            .sort(function(a,b){
                                    // Sort the event data so it displays properly
                                    const eventA = a.time.start
                                    const eventB = b.time.start
                                    const eventAPromoted = a.promotion.applies
                                    const eventBPromoted = b.promotion.applies

                                    let comparison = 0
                                    if (   ( eventAPromoted && eventBPromoted && eventA < eventB )  ||
                                           ( eventAPromoted && !eventBPromoted )    ||
                                           ( !eventAPromoted && !eventBPromoted && eventA < eventB )
                                           
                                           ) { comparison = -1 } 



                                        else if ( 
                                            ( eventAPromoted && eventBPromoted && eventA > eventB ) ||
                                            ( !eventAPromoted && eventBPromoted)    ||
                                            ( !eventAPromoted && !eventBPromoted && eventA > eventB )
                                            
                                            
                                            ) { comparison = 1 }




                                    return comparison
                                })
                            .map((e, index) => 
                            // Return an EventListItem for each Event found
                                <EventListItem  transitionDelay={0}
                                                key={e.id} 
                                                data={e}/>
                                )}
                    </div>
            ): 
                // If no dates were returned, display a notice
                null
                // <TileListItem>
                //                 <div className='.font-alt .upper .secondary'>No events found</div>

                // </TileListItem>


            }

            {   view.type === 'all' ?
                        <TileListItem> 
                            <LazyLoad   lead={20}
                                        noResultMessage='No more events found.'
                                        status={dataStatus.fetching}
                                        onVisible={
                                                emptyFetchCount < 3  && viewData.length > 0 ? 
                                                        function(){ setDataPage(currentDataPage => { return (currentDataPage += 1) })}
                                                    :   function(){} }
                                        onNotVisible={ function(){/*Empty function, do nothing*/} }/>
                        </TileListItem>
                        : null }


            
        </div>

    )
}

export default EventList