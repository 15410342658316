import React, {useContext} from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom' // Link, NavLink, Switch
// import {firebase} from '../firebase'

// Import components
import { SystemNotifications } from '../components/Notifications'
import { PreferencesManager } from '../components/PreferencesManager'
import { LegalManager } from '../components/LegalManager'
// import Login from '../components/login'
import { HomeView } from '../components/HomeView'
import { ListView } from '../components/ListView'
import { EventView } from '../components/EventView'
import { ArticleView } from '../components/Article'

import RedirectDefault from '../components/redirect-default'
import { UIProvider, UIContext } from '../context/UI'
import { AuthProvider } from '../context/Auth'
import { UserProvider } from '../context/User'
import { DataProvider} from '../context/Data'
import { DataService } from '../services/DataService'

import { Privacy, TermsConditions, Disclaimer } from '../components/Legal'

import { SystemNotificationsProvider } from '../context/SystemNotifications'
// import PrivateRoute from '../routes/private-route'
// import LogoutButton from '../components/Logout'
import {  Navbar  } from '../components/Navbar'




// Styles
import '../index.css'
import 'remixicon/fonts/remixicon.css'
import { fromBase64 } from 'bytebuffer';
import { LegalManagerComponent } from '../components/LegalManager/legal-component';


// TODO: create a user context
// -- if logged in, get remote settings
// -- if not logged in, get local settings
// -- if change to logged in, sync state (where appropriate)
// -- ensure any sensitive stuff is set by server functions
//    -- e.g. write updates on user acceptance etc.

// TODO: safety context
// -- check the user has accepted safety terms etc
// -- if not, direct them to those screens
// -- do this when appropriate, e.g. still allow a direct event path
// -- but if they want the full list, they must accept.
// -- maybe just reserve this for sign ups

// <Logout/>
const App = () => {



    // const version = '0.0.1a'
    // const { onlineStatus, setOnlineStatus } = useContext(AuthContext)

    // if (!onlineStatus){setOnlineStatus(true)}


// up to here
    return(


          <div className={`app-container`} id='app'>
            <UserProvider>
              <UIProvider>
                <PreferencesManager/>
                <LegalManagerComponent/>
                <SystemNotificationsProvider>
                  <SystemNotifications/>
                </SystemNotificationsProvider>

                <BrowserRouter>
                  <Navbar/>

                  <DataProvider>
                  <DataService needsEventFeed = {true} needsNewsFeed={true}/>
                    <Switch>
                      <Route path='/policies/privacy' component={Privacy}/>
                      <Route path='/policies/terms' component={TermsConditions}/>
                      <Route path='/policies/disclaimer' component={Disclaimer}/>

                      <Route path='/events' component={ListView}/>
                      <Route path='/event/' component={EventView}/>
                      <Route exact path='/news' component={HomeView}/>
                      <Route exact path='/article/:id' component={ArticleView}/>
                      <Route path='/' component={RedirectDefault}/>      
                      

                    </Switch> 
                  </DataProvider>


              </BrowserRouter>

            </UIProvider>
          </UserProvider>
         </div>

    )

}

// Private Route model
// <Route path='/event/:eventid' component={EventView}/>
//                 <Route exact path='/login' component={Login}/>

//<Route component={RedirectDefault}/>
export default App;



// Browserr outer

// <SystemNotificationsProvider>
// <SystemNotifications/>
// </SystemNotificationsProvider>

 // under system notifications

// <BrowserRouter>
//     <Navbar/>



//     <DataProvider>
//     <DataService needsEventFeed = {true} needsNewsFeed={true}/>
//       <Switch>

//         <Route path='/events' component={ListView}/>
//         <Route path='/event/' component={EventView}/>
//         <Route exact path='/news' component={HomeView}/>
//         <Route exact path='/article/:id' component={ArticleView}/>
//         <Route path='/' component={RedirectDefault}/>      
        

//       </Switch> 
//     </DataProvider>


// </BrowserRouter>