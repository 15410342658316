import React from 'react'
import '../hoc/ui-verified.css'

const UIVerified = (props) =>{

    const className = props.className !== undefined ? props.className : ''

    return (
        <div className={`UIVerified ${className}`}>
            <i className='ri-check-line UIVerifiedCheck'/>
            <i className='ri-shield-fill UIVerifiedShield'/>
        </div>
    )
}

export default UIVerified

