import React from 'react'
import Moment from 'moment'

import {getRelativeTime} from '../hoc/getRelativeTime'
import TimeDisplay from './time-display'
import DateDisplay from './date-display'

import styles from '../hoc/datetime-display.css'

const DateTimeDisplay = ({
    dateStart = '',
    timeStart = '',
    timeEnd = '',
    cancelled,
    ...restProps

}) =>{

    const displayDateStart = dateStart !== '' ? Moment(dateStart.toDate()) : ''
    const displayTimeStart = timeStart
    const displayTimeEnd = timeEnd

    if (cancelled) {
        return(
        <div
            className='DatetimeDisplayContainer'>
        <div className='cancelledEvent'><span>cancelled</span></div>
        </div>
        )
    }


    return (
        <div
            className='DatetimeDisplayContainer'>

            <div
                className={`font-regular secondary medium spacing-medium DatetimeRelative`}>
                {getRelativeTime(dateStart)}
            </div>
            <DateDisplay date={displayDateStart}/>
            <TimeDisplay
                size={'large'}
                timeStart={displayTimeStart} 
                timeEnd={displayTimeEnd}
            />
        </div>
    )
}

export default DateTimeDisplay

