import React, { useEffect, useContext, useState, Fragment } from 'react'

import { UserContext } from '../../context/User'
import { UIContext } from '../../context/UI'
import { ButtonText } from '../../hoc/button'

import { regions } from '../../defaults/default-config'

import './preferences-manager.css'

const setRegion = () => {
    // const { preferences, setPreferences } = useContext(UserContext)

    // setPreferences(preferences => { return {...preferences, region: 'South-East Queensland' }})

}


const PreferencesManagerComponent = () => {

    const { showPreferences, setShowPreferences, setModalActive } = useContext(UIContext)
    const { preferences, setPreferences } = useContext(UserContext)

    const{ viewNeedsPreferences, setViewNeedsPreferences } = useContext(UserContext)
    const [ selection, setSelection ] = useState( preferences.region || null )
    const regionsFiltered = regions.filter(region => {
        return region.enabled && region.value !== 'ALL'
    })

    const handleChange = (e) => {
        setSelection(e.target.value)
    }


    const handleSave = () => {

        setPreferences(preferences => { return {...preferences, region: selection}})
        setShowPreferences(false)
        setModalActive(false)
    }

    const handleCancel = () => {
        setSelection(preferences.region)
        setShowPreferences(false)
        setModalActive(false)
    }

    useEffect(()=> {
        if (preferences.region === null && viewNeedsPreferences) {
            setShowPreferences(true)
            setModalActive(true)
        }
    }, [preferences, viewNeedsPreferences])


    if (!showPreferences){ return null }

    if (showPreferences){ return(
        <Fragment>
        <div className={'blurbackground'}/>
        <div className={'blockbackground'} onClick={() => {}}>
        <div className={'PreferencesManagerComponent'}>
        
            <h1>Your preferences</h1>
            <h2>Region</h2>
            <p>Which area you would like to see events from?</p>

            <ul className='options'>

            {
                regionsFiltered.map(region => 
                    <li key={region.value}>
                        <input  id={`option-${region.value}`} 
                                className='option-toggle' 
                                name='region' 
                                type='radio' 
                                defaultChecked={region.region === preferences.region || false}
                                value={region.region} 
                                onChange={(e)=>{handleChange(e)}}/>
                        <label htmlFor={`option-${region.value}`}>{region.region}
                        <i id={`option-${region.value}-icon`} 
                        className={`${region.region === selection ? 'ri-checkbox-blank-circle-fill' : 'ri-checkbox-blank-circle-line' } toggle-icon`}/> 
                        </label>
                        
                    </li>

                )

            }
            </ul>

            <div className='buttons-container'> 
            {preferences.region !== null ? <ButtonText onClick={function(){handleCancel()}}>Cancel</ButtonText> : null }
            <ButtonText onClick={function(){handleSave()}}>Save</ButtonText>
            </div>
        
        </div>
        </div>
        </Fragment>

    )}
    

}

export { PreferencesManagerComponent, setRegion }