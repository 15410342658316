import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'moment'

import TagListDisplay from '../../hoc/tag-list-display'
import getEventTagsList from '../../hoc/getEventTagsList'
import {OperatorLogo} from '../../hoc/identity'
import UIVerified from '../../hoc/ui-verified'


import './event-list-item.css'


const EventListItem = (props) => {

    const event = props.data
    const transitionDelay = props.transitionDelay + 2
    const featured = props.featured || false

    // TODO: Logic to handle prices
    const price = parseFloat(event.payment.listprice) || null


    let config = {
        // Display information
        operatorName: event.operator.name
        ,eventName: event.name
        ,eventLocation: event.location.data.address.suburb
        ,timeStart: Moment(event.time.start, 'H:mm')
        ,timeEnd: event.time.end ? Moment(event.time.end, 'H:mm').format('h:mmA') : ''
        ,priceTag: price !== 0 && price ? `$${price.toFixed(2)}` : ''
        ,callsign: event.operator.data.identity.callsign || null
        ,icon: event.operator.data.identity.logoUrl || null // todo: import dynamically
        ,link: `/event/${event.id}`

        ,status: event.status


        //Status effects
        ,verifiedOperator: event.operator.data.verified.status || false
        ,newAddition: false
        ,promotedEvent: event.promotion.applies || false
        ,recentlyUpdated: false

        ,featuredImage: event.promotion.featuredImageUrl || 'https://media.giphy.com/media/YTK7OXwtiDmCmV2bd5/giphy.gif'

        
    }

    return (

            <Link 
                style       =   { config.promotedEvent ? {backgroundImage: `url(${config.featuredImage})`} : null }
                className   =   {   `event-list-item content-container
                                    ${ config.promotedEvent ? 'event-list-item-promo' : ''}  
                                    ${ featured ? 'featured' : ''}
                                    ${ config.status.toLowerCase() === 'cancelled' ? 'cancelled' : '' }
                                `}
                
                to={{
                    pathname: `${config.link}`
                    ,state: {...props}
                }}>
                <div className={`event-item-bg-effects content-container`}>
                <div className='event-item-data'>
                    { config.status.toLowerCase() === 'cancelled' ? <div className='overlayCancelled'><span>Cancelled</span></div> : null}
                    <h3 className='item-operator fade'>{`${config.operatorName}`}</h3>

                    <div className='eventListItemIconContainer fade'>
                        <OperatorLogo   className={`eventlistItemIcon ${ config.promotedEvent ? 'iconPromoted' : ''}`}
                                        logoType='default'
                                        logoContent={config.operatorName.substring(0,3)}
                                        logoText={config.callsign}
                                        logoUrl={config.icon}
                                        verified= {config.verifiedOperator}
                                        />                        
                    </div>


                    <div className='item-name fade'>{`${config.eventName}`}</div>
                    <div className='item-location detail fade'>{`${config.eventLocation}`}</div>


                    <div className='item-time label-mono-value light fade' >
                        <span className='accent'>{`${config.timeStart.format('h:mmA')}`}</span>
                        <i className='ri-arrow-right-line eventListItemTimeArrow'/>
                        {`${config.timeEnd}`}
                    </div>


                    <TagListDisplay
                        className='EventListItemTags fade'
                        displayMode={'list'}
                        tags={getEventTagsList(event)} 
                    />


                    <div className='item-price label-mono-value fade'>{`${config.priceTag}`}</div>
                    
                    <div className='item-link button-link action fade' to={{
                        pathname: `${config.link}`
                        ,state: {...props}
                    }}>
                        <div><i className='ri-arrow-right-s-line '/></div>
                    </div>



                </div>
            </div>



            </Link>

    )

}


export default EventListItem