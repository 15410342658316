import React from 'react'

import {defaultDisclaimer} from '../defaults/default-data'
import TopicHeader from '../hoc/topic-header'

import '../components/disclaimer.css'

export const Disclaimer = (props) => {
    
    const className = props.className === undefined ? '' : props.className

    return (

        
        <div className={className}>
        <TopicHeader    heading={'Disclaimer'}
                        subheading={''}
                        className=''/>
                    <div className='DisclaimerString'>
                        { defaultDisclaimer() }
                    </div>

        </div>
    )
}


export default Disclaimer