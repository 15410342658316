import React, {useEffect, useContext} from 'react'
import { Link, useLocation, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import {Helmet} from 'react-helmet'
import moment from 'moment'

import { UIContext } from '../../context/UI'

import EventList from './event-list'
import EventFilters from './event-filters'

import ScrollToTop from '../../hoc/scroll-to-top'


import './list-view.css'
import { UserContext } from '../../context/User'


const EventsComponent = (props) =>{
    const { setAutoNavbar, setShowBack, setShowNavbar } = useContext(UIContext)
    const { viewNeedsPreferences, setViewNeedsPreferences, preferences } = useContext(UserContext)


    var scroll = queryString.parse(useLocation().search).pos === 'top' || false

    const search = queryString.parse(props.location.search)
    const view = search.view || null
    let viewFilter

    

    switch (String(view).toLowerCase()) {
        case 'featured':
            // console.log('Show featured events!')
            viewFilter = {
                type: 'featured'
            }
            break

        case 'all':
            // console.log('Show featured events!')
            viewFilter = {
                type: 'all'
        }
        break
        
        case 'following':
            // console.log('Show featured events!')
            viewFilter = {
                type: 'followed'
        }
        break

        case 'today':
            // console.log('Show events for today!')
            viewFilter = {
                type: 'date',
                value: moment().startOf('day').toDate()
            }
            break
        default:


            // See if the query is a date
            const viewDate = moment(view)

            if (viewDate.isValid()){
                // If the date is valid, set the view

                // console.log('Show events for', viewDate.toDate())
                viewFilter = {
                    type: 'date',
                    value: viewDate
                }
            } else {

                // Otherwise.. show the default view
                // console.log('Show default view...')
                viewFilter = {
                    type: 'all'
                }
            }

            break
    }

    const onLoad = () => {
        setAutoNavbar(true)
        setShowBack(false)
        setShowNavbar(true)
    }

    useEffect(()=> {
        onLoad()
        window.history.replaceState(null, null, 'events?view=all') // need logic here to inherit actual filters
        if (!viewNeedsPreferences) {
            setViewNeedsPreferences(true)
        }
    },[])

    return(
        <div className='blurrable ListViewContainer'>
            <Helmet>
                <title>{`SOFTOPS | Games in ${preferences.region}`}</title>
            </Helmet>
            { scroll ? <ScrollToTop/> : null }
            <EventFilters view={viewFilter}/>

            <div className='content-container' id='event-list-partition'>
                <EventList view={viewFilter}/>
            </div>

        </div>
    )



}

export { EventsComponent }