import React, {useEffect, useRef, useState, useContext} from 'react'
import FilterRegion from './filter-region'
import FilterDate from './filter-date'
import FilterView from './filter-view'
import SoftopsLogo from '../../hoc/softops-logo.js'
import ButtonInstallApp from '../../hoc/button-installapp'


import {PreferencesManager} from '../PreferencesManager'

import {    UIContext   }   from '../../context/UI'
import {    DataContext }   from '../../context/Data'
import {    UserContext }   from '../../context/User'



import './event-filters.css'

const EventFilters = () => {

    const { preferences, setPreferences } = useContext(UserContext)
    const { setShowPreferences, setModalActive }  = useContext(UIContext)
    

    const [ visible, setVisible ] = useState(true)
    const [ position, setPosition ] = useState()

    function usePrevious(value) {
        // Track the previous value of the window position
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        }, [value])

        return ref.current
    }

    const prevPosition = usePrevious(position)


    useEffect(()=> {
            setVisible(visible => {
                // Used to give some leeway on microscroll
                const stickyness = 10
                
                if ( prevPosition > position ) {
                    // We scrolled up
                    // But check it against stickyness
                    if ( prevPosition > position + stickyness ) {
                        if(!visible){
                            return(true)
                        }}
                } else {
                    // We scrolled down
                    // But check it against stickyness
                    if ( prevPosition < position - stickyness ) {
                        if(visible){
                            return(false)
                        }}
                }

                return(visible)
            })

    }, [position])

    useEffect(()=> {   

        const changePosition = () => {
            setPosition(window.pageYOffset)
        }
        window.addEventListener('scroll', changePosition)
        return () => window.removeEventListener('scroll', changePosition)
    }, [])
    

    const popup = () => {

        setModalActive(true)
        setShowPreferences(true)

    }


    return(
        <div className = {`EventFiltersContainer ${visible ? 'show' : 'hide'}`}>

            <div className = 'content'>
                <h1>Games</h1>
                <h2>{`${preferences.region}`}</h2>
                <div className='settings-container' onClick={ function(){
                    popup()
                }
            }>
                <i      className={`ri-settings-4-fill settings `} 
                        
                    />
                </div>

                <FilterView/>
            </div>
            

                


        </div>
    )



}

export default EventFilters

