import React, {useEffect, useRef, useState, useContext} from 'react'

import ViewHeaderLinks from './view-header-links'
import SoftopsLogo from '../../hoc/softops-logo.js'


import {DataContext} from '../../context/Data'
import {UserContext} from '../../context/User'



import './view-header.css'

const ViewHeaderComponent = () => {

    const { preferences, setPreferences } = useContext(UserContext)

    return(
        <div className = {`ViewHeaderContainer`}>

            <div className = 'content'>
                <h1><SoftopsLogo/></h1>

                <ViewHeaderLinks/>
            </div>
            

                


        </div>
    )



}

export {ViewHeaderComponent}

