import React from 'react'
import Moment from 'moment'

const DateDisplay = (props) =>{

    const date = Moment(props.date.toDate())

    return (
        <div 
            className={`font-technical medium-w upper spacing-large large`}>

            {date.format('dddd, D MMM')}

        </div>
    )
}

export default DateDisplay

