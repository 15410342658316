import React from 'react'
import styles from '../hoc/topic-header.css'

const TopicHeader  = (props) =>{

    /*
    TODO:
    - Some logic to convert time and timezones
    - Some logic to handle styling
    - Some logic to handle positioning

    */

    // const fontSize = props.fontSize
    const heading = props.heading
    const subheading = props.subheading
    const className = props.className ? props.className : ''
    const inverted = props.inverted || false


    return (

        <div className={`TopicHeaderContainer ${className}`}>
        {inverted ? 
        <>
        <div
            className={`font-regular secondary medium spacing-medium TopicHeaderSubheading ${inverted? 'subheaderInvert':''}`}>
                {subheading}
                </div>
                <h2 className={`medium-w TopicHeaderHeading ${inverted ? 'headerInvert':''}`}>{heading}</h2>
        </>
            :
        <>            
            <h2 className={`medium-w TopicHeaderHeading ${inverted ? 'headerInvert':''}`}>{heading}</h2>
            <div
            className={`font-regular secondary medium spacing-medium TopicHeaderSubheading ${inverted? 'subheaderInvert':''}`}>
                {subheading}
                </div></>       
        }

        </div>

    )

}


export default TopicHeader

