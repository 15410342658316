import React, { useEffect } from 'react'
import uniqueid from 'uniqid'

 const WebVideoViewerComponent = ({ src, className }) => {

    const id = uniqueid()

    useEffect(() => {
        var webvideoEl = document.getElementById(`webvideo-${id}`)
            webvideoEl.muted = true
            webvideoEl.oncanplaythrough = function() {
                
                webvideoEl.loop = true
                webvideoEl.play();
                
            }

    })

    return (
        <video id={`webvideo-${id}`} controls  autoPlay={true} muted={true} name='media' className={className}>
            <source src={src} type='video/mp4'/>
        </video>
    )
  }


  export { WebVideoViewerComponent }