import React, {useContext} from 'react'
import { NavLink } from 'react-router-dom'
// import moment from 'moment'

import ViewBar from '../../hoc/view-bar'
import './view-header-links.css'

// import { DataContext } from '../../context/Data'
// import { UserContext } from '../../context/User'
// TODO: include date context


const ViewHeaderLinks = (props) => {
  
    const viewList = [
        { label: 'News', link:'news', enabled: true}, 
        { label: `TV`, link: 'community', icon: 'ri-tv-line', enabled: false},
        { label: 'Guides', link: 'guides', enabled: false}
    ]

    return(
        <ViewBar 
            type=''
            className='view-options ViewHeaderLinksBar'>
            <ul className='FilterViewList'>
                {viewList.map(view => (

                    view.enabled ?
                            <li key={view.link} 
                                className='ViewHeaderLinks-item'>
                                <NavLink    className='ViewHeaderLinksOption' 
                                            activeClassName='chosen'
                                            to={`/${view.link}`}
                                            isActive={(match, location) => {
                                        // Check if the path+query matches to flag active
                                                                            if (`${location.pathname}${location.search}` === `/${view.link}`) {

                                                                            return true;
                                                                            }
                                                                            return false;
                                            }}>
                                    {view.icon && <i className={view.icon}/>}
                                    <span>{view.label}</span>
                                </NavLink>
                            </li> : null
                    ))
                }
            </ul>                        
        </ViewBar>


    )
}

export default ViewHeaderLinks