import React, { useState, useEffect, useRef, useContext } from 'react'
import {NavLink} from 'react-router-dom'

import { UIContext } from '../../context/UI'

import './navbar-component.css'


const NavbarComponent = () =>{

    const { autoNavbar, showBack, showNavbar } = useContext(UIContext)

    const [ visible, setVisible ] = useState(true)
    const [ position, setPosition ] = useState()

    const options = [
        {   text: 'News',
            link: {
                pathname: '/news',
                search: '?pos=top'
            },
            icon: 'ri-fire-line',
            col: 1
        },
        {   text: 'Games',
            link: 
            {
                pathname: '/events',
                search: '?view=all&pos=top'
            },
            icon: 'ri-calendar-todo-line',
            col: 2
        },
        // {   text: 'Stores',
        //     link: '/stores',
        //     icon: 'ri-arrow-left-line',
        //     col: 3
        // },        
        // {   text: 'Profile',
        //     link: '/profile',
        //     icon: 'ri-qr-code-line',
        //     col: 3
        // }

    ]

    const back =  {   text: 'Back',
                            link: '/stores',
                            icon: 'ri-arrow-left-line',
                            col: 3
                   }


    function usePrevious(value) {
        // Track the previous value of the window position
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        }, [value])

        return ref.current
    }

    const prevPosition = usePrevious(position)


    useEffect(()=> {

        if (autoNavbar){
            setVisible(visible => {
                // Used to give some leeway on microscroll
                const stickyness = 10
                
                if ( prevPosition > position ) {
                    // We scrolled up
                    // But check it against stickyness
                    if ( prevPosition > position + stickyness ) {
                        if(!visible){
                            return(true)
                        }}
                } else {
                    // We scrolled down
                    // But check it against stickyness
                    if ( prevPosition < position - stickyness ) {
                        if(visible){
                            return(false)
                        }}
                }

                return(visible)
            })
        }

        if (!autoNavbar && !visible) {
            setVisible(true)
        }

    }, [position, autoNavbar])

    useEffect(()=> {   

        const changePosition = () => {
            setPosition(window.pageYOffset)
        }


        window.addEventListener('scroll', changePosition)
    }, [])


    return (
        <div className={`Navbar ${visible ? 'show' : 'hide'} ${showNavbar ? '' : 'nonav' }`} id='navbar'>

            <div className='width-container'>
                <ul className='NavbarLinksContainer'>
                    { options.map( option =>
                        <li key={option.text}
                            className='NavbarLink'
                            style={{gridColumn: option.col}}>
                            <NavLink 
                                activeClassName='chosen'
                                className='button-link nav-links NavBarLinkContent' 
                                to={option.link}>
                                
                                
                                <i className={`${option.icon} NavbarLinkIcon`}/>
                                <div className='NavbarLinkText'>
                                    {option.text}
                                </div>
                            
                            </NavLink>
                            </li>
                        )}

                    { showBack ? 
                        <li key={back.text}
                            className='NavbarLink soft-cta'
                            style={{gridColumn: back.col}}>
                            <div 
                                className='button-link nav-links NavBarLinkContent backLink' 
                                onClick={function(){window.history.back()}}>
                                
                                
                                <i className={`${back.icon} NavbarLinkIcon`}/>
                                <div className='NavbarLinkText'>
                                    {back.text}
                                </div>
                            
                            </div>
                        </li> : null
                    }

                    
                </ul>
            </div>

        </div>
    )

}

export {NavbarComponent}