import React from 'react'

import './tile-list-item.css'

const TileListItem = ({ className = '', display, children,
                        ...restProps
                                    }) => {

    return (

            <div 
                className={`TileListItemContainer content-container ${className}`}>
                <div className={`TileListItemContent  ${display || ''} `}>
                    { children }
                </div>
            </div>

    )

}


export { TileListItem }