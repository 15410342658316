// Manages data requirements and requests
//      All request activity should occur HERE, including logic for when it is appropriate to
//      fulfill a data request.
//
//      Logic for how the actual processing of the request should occur in Data.js


// TODO: Something not right - hitting the 'back' button resets the data

import  { useContext, useEffect, useState } from 'react'
import Moment from 'moment'

import { DataContext } from '../context/Data'
import { AuthContext } from '../context/Auth'
import { UserContext } from '../context/User'


// Dispatch an event to kick off data retrieval
const getEventData = (config = {method: 'null'}) => {
    const getEventData = new CustomEvent('getEventData', {detail: config})
    window.dispatchEvent(getEventData)
    
}




// Dispatch an event to kick off data retrieval
const getNewsFeed = (config = {method: null}) => {
    const moreConfig    = { method: 'more'}
    const getNewsFeed = new CustomEvent('getArticleData', {detail: moreConfig})
    window.dispatchEvent(getNewsFeed)

    const getPinnedArticles = new CustomEvent('getPinnedArticles', {detail: moreConfig})
    window.dispatchEvent(getPinnedArticles)
}


const getSingleArticle = (id = null) => {
    // Dispatch an event to kick off data retrieval for a single event
    if (id !== null){
        const singleConfig      = { method: 'single', id: id }
        const getArticleSingle    = new CustomEvent('getArticleData', {detail: singleConfig})

        window.dispatchEvent(getArticleSingle)
    } else {
        console.error('An id must be provided to retrieve data.')
    }
}

const getMoreEvents = (region = null) => {

    if (region !== null ){
        
        const moreConfig    = { method: 'more', region: region }
        const getMoreData   = new CustomEvent('getEventData', {detail: moreConfig})

        window.dispatchEvent(getMoreData, false)
    } else {
        console.error('A region must be provided to retrieve data.')
    }
}

const getEventFeedData = (region = null, initial = false) => {
    // Dispatch an event to kick off data retrieval for the standard listing

    if (region !== null){
        
        const regionalConfig    = { method: 'region', region: region }
        const featuredConfig    = { method: 'featured' }
        const followingConfig   = { method: 'following'}

        const getRegionalData   = new CustomEvent('getEventData', {detail: regionalConfig})
        const getFeaturedData   = new CustomEvent('getEventData', {detail: featuredConfig})
        const getFollowingData  = new CustomEvent('getEventData', {detail: followingConfig})

        window.dispatchEvent(getRegionalData, false)
        window.dispatchEvent(getFeaturedData)
        window.dispatchEvent(getFollowingData)

    } else {
        console.error('A region must be provided to retrieve data.')
    }
}


const getEventSingleData = (id = null) => {

    // Dispatch an event to kick off data retrieval for a single event
    if (id !== null){
        const singleConfig      = { method: 'single', id: id }
        const getEventSingle    = new CustomEvent('getEventData', {detail: singleConfig})

        window.dispatchEvent(getEventSingle)
    } else {
        console.error('An id must be provided to retrieve data.')
    }
}

const DataService = ({  needsEventFeed = false,
                        needsNewsFeed = false,
                        ...restProps}) => {


    // const [ queryHistory, setQueryHistory ] = useState([])
    const { preferences }           = useContext(UserContext)
    const { onlineStatus }          = useContext(AuthContext)
    const { 
            // EVENT DATA
            currentEventData,

            currentDataPage,
            setDataPage,
            currentStartAt,
            setStartAt,
            latestStartAt,
            queryHistory, 
            setQueryHistory,
            currentDateFilters,
            setDateFilters,
            latestDateFilters,
            emptyFetchCount,
            setEmptyFetchCount,


            // NEWS DATA
            currentArticleData, 
            currentNewsPage,
            setNewsPage,
            currentNewsStartAt,
            setNewsStartAt,
            latestNewsStartAt,
            newsQueryHistory,
            newsEmptyFetchCount,
            setNewsEmptyFetchCount,



            // STATUS DATA
            ready               }   = useContext(DataContext)



    const isReady = (data) => {
        const status = data.status
        const result =  !status.fetching &&
                        // !status.attempted &&
                        !status.outcome !== 'failed'
        return result
    }

    const hasQueried = (region, queryType) => {
        var search = queryHistory.filter(history => {
            return history.queryType === queryType && history.region === region
        })
        return !(search.length === 0)
    }

    const hasQueriedNews = (queryType) => {
        var search = newsQueryHistory.filter(history => {
            return history.queryType === queryType
        })
        return !(search.length === 0)
    }

    const queryFeed = () => {
        const attemptThreshold = 3  

        if(needsEventFeed){ // TODO: need to fix the hasQueried for new lazyload method
            if ( emptyFetchCount < attemptThreshold && hasQueried(preferences.region, 'region') && currentDataPage > 0  && isReady(currentEventData)) {  getMoreEvents(preferences.region) }
            else if ( emptyFetchCount < attemptThreshold 
                        && !hasQueried(preferences.region, 'region') 
                        && currentDataPage === 0) {
                            // console.log('trying')
                             setStartAt(null)
                             getEventFeedData(preferences.region, true)
                            }  
        }        

    }


    const queryNews = () => {
        const attemptThreshold = 3  

        if (needsNewsFeed){ 
            if ( newsEmptyFetchCount < attemptThreshold && hasQueriedNews('more') && currentNewsPage > 0  && isReady(currentArticleData)) { getNewsFeed() }
            else if ( newsEmptyFetchCount < attemptThreshold 
                        && !hasQueriedNews('more')
                        && currentNewsPage === 0) {
                            
                             setNewsStartAt(null)
                             getNewsFeed()
                            } 
            // if(isReady(currentArticleData))     {   getNewsFeed() }}
        }
    }

    useEffect(()=> {
        // console.log('resetting startAt')
        setStartAt(null)
        latestStartAt.current = null
        queryFeed() // This will only work if the datapage is still 0
    }, [preferences])


    useEffect(()=> {
        if (currentStartAt === null) {
            setDataPage(currentDataPage => { return 0 })
            setEmptyFetchCount(0)
        }
    }, [currentStartAt])

    useEffect(()=> {
        if (currentNewsStartAt === null) {
            setNewsPage(currentNewsPage => { return 0 })
            setNewsEmptyFetchCount(0)
        }
    }, [currentNewsStartAt])

    
    useEffect(()=> {
        // console.log(currentDataPage)
        queryFeed()
        
    }, [ready, currentDataPage])

    useEffect(()=> {
        // console.log(currentDataPage)
        queryNews()
    }, [ready, currentNewsPage])




    return (null)

}

export {
    DataService,
    getEventData,
    getNewsFeed,
    getEventSingleData,
    getSingleArticle
    
}

