const getEventTagsList = (eventData) =>{

    // console.log('Checking Tags', eventData)

    // DEFAULT TAG OBJECT
    const tag = (label, type = null) => {
        return { label: label, type: type}
    }

    // DETERMINE WHAT AGE LIMITS APPLY
    const ageData = eventData.ages
    let tagAges = null
    if (ageData.applies) {
        if ( ageData.min !== null && ageData.max !== null) {
            tagAges = { label: `${ageData.min}-${ageData.max}`, type: 'warn' }
        } else if ( ageData.min !== null ) {
            tagAges = { label: `${ageData.min}+`, type: 'warn' }
        } else {
            tagAges = { label: `U${ageData.min}`, type: 'warn' }
        }
    }

    // DETERMINE WHAT FEATURES APPLY
    const featureData = eventData.type.gameplay.features
    const tagFeatures = [
        featureData.bush ? tag('bush') : null,
        featureData.cqb ? tag('cqb') : null,
        featureData.dark ? tag('dark') : null
    ]
    

    // OTHER TAGS
    const tagPrebook = eventData.prepaid ? tag('Prebook', 'warn') : null
    const tagFPS = eventData.limits.applies ? tag(`${eventData.limits.maxFPS}FPS`, 'warn') : null
    const tagCrowdType = tag(eventData.type.gameplay.event, eventData.type.gameplay.event === 'comp' ? 'warn' : null )
    const tagGameType = tag(eventData.type.gameplay.type)


    // COMBINE INTO AN ARRAY
    let tagsList = [
        tagPrebook,
        tagCrowdType,
        tagGameType,
        tagAges,
        // ...tagFeatures,
        tagFPS,
    ]

    // console.log('Returned tag objects', tagsList)
    return tagsList
}


export default getEventTagsList

