import React from 'react'
// import logo from '../assets/softops-text.png'
import { ReactComponent as Icon} from '../assets/icons/txt-softops.svg'

import './softops-logo.css'

const SoftopsLogo = () =>{

    return (

        <div className='logo-softops logo-top-left' alt='SOFTOPS Logo'>
            <Icon/>
        </div>
    )

}

export default SoftopsLogo 

