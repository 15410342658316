import React from 'react'

// Styles
import styles from '../hoc/address-display.css'


// HOC
import { ButtonText } from './button'
import { getGoogleMapsURL } from '../hoc/url-google-maps'


const AddressDisplay = (props) =>{


    const locationName = props.locationData.name
    const addressData = props.locationData.data.address
    const addressLine1 = addressData.streetAddress
    const addressLine2 = [addressData.suburb, addressData.state, addressData.postcode].join(" ")


    return (
        <div 
            className='AddressDisplayContainer'>
            
            <div className={`font-technical large upper spacing-large AddressLocationName`}>{locationName}</div>

            <div className='AddressStreetDetails'>
                <div className={`font-regular small secondary`}>{addressLine1}</div>
                <div className={`font-regular small secondary`}>{addressLine2}</div>
            </div>





        </div>
    )

}


export default AddressDisplay

// <ButtonText
// icon='ri-map-pin-2-line'
// className='AddressOpenMap'
// clickType='URL'
// onClick= { function(){
//          window.open(getGoogleMapsURL(
//                                     {   lat:    props.locationData.data.coords.lat, 
//                                         long:   props.locationData.data.coords.long}), '_blank') 
//                                     }}
    
// >
// Google Maps
// </ButtonText>