// Control the UI state - what components do we want to show, and when?

import React, {useEffect, useState} from 'react'



export const UIContext = React.createContext()

export const UIProvider = ({ children }) => {

    const [ showPreferences, setShowPreferences ]   = useState(false)
    const [ showLegal, setShowLegal ]               = useState(false)
    const [ modalActive, setModalActive ]           = useState(false)
    const [ scrollPos, setScrollPos ]               = useState(0)
    const [ autoNavbar, setAutoNavbar ]             = useState(false)
    const [ showNavbar, setShowNavbar ]             = useState(true)
    const [ showBack, setShowBack ]                 = useState(false)

    // Values to watch for modals
    // useEffect(() => {
    //     console.log('setprefvis')

    //     if ( !showPreferences ||
    //          false
    //          ) {
    //              setModalActive(true)
    //          }

    // }, [showPreferences])


    useEffect(()=> {
        const tgt = document.getElementById('app')

        if(modalActive){
            // console.log(window.scrollY)
            setScrollPos(window.scrollY)

            // console.log('modal')
            tgt.classList.add('modalactive')

        } else if (!modalActive) {
            
            tgt.classList.remove('modalactive')
            window.scrollTo(0, scrollPos)

        }


    }, [modalActive])

    return (
        <UIContext.Provider
            value={{
                modalActive,
                setModalActive,
                showPreferences,
                setShowPreferences,
                showLegal, setShowLegal,
                autoNavbar, setAutoNavbar,
                showNavbar, setShowNavbar,
                showBack, setShowBack
            }}
            >
        {children}
        </UIContext.Provider>
            
    )

}
