import React, {useEffect, useState} from 'react'


export const SystemNotificationsContext = React.createContext()

export const SystemNotificationsProvider = ({ children }) => {
    const [currentSystemNotifications, setSystemNotifications] = useState([])



    useEffect(() => {
        function handleNewMessage() {
            let notifications = ['This app has been updated.']
            setSystemNotifications(currentSystemNotifications => [...currentSystemNotifications, notifications])
          }

          window.addEventListener('newsystemnotification', handleNewMessage);
          return () => window.removeEventListener('newsystemnotification', handleNewMessage);
    }, [])

    return (
        <SystemNotificationsContext.Provider
        value={{
            currentSystemNotifications, setSystemNotifications
        }}
        >
        {children}
        </SystemNotificationsContext.Provider>
            
    )

}