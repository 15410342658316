import Moment from 'moment'

export const getRelativeTime = (datetime) => {

    const refDate = Moment(datetime.toDate())
    const now = Moment()
    const diff = parseInt(refDate.diff(now, 'd'))
    const weekDiff = parseInt(refDate.diff(now.startOf('week'), 'w'))
    let string = null

    // console.log('Diff=', diff)
    // console.log('WeekDiff=', weekDiff)

    if (diff < 5 && weekDiff === 0){
        string = refDate.calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: '[This] dddd', // Need logic to give day count
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'DD/MM/YYYY'
        })
        // console.log('Date check', string)
    } else if (diff > 7 && weekDiff-1 < 5 ){
        string = `in ${Math.round(diff/7, 0)} week${ Math.round(diff/7, 0) > 1 ? 's':''}`    
    } else {
        string = refDate.fromNow()
        // console.log('Date check 2', string )
    } 
    return string
}