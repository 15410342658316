import React, { useEffect, useContext, useState, Fragment } from 'react'

import { UserContext } from '../../context/User'
import { UIContext } from '../../context/UI'
import { ButtonText } from '../../hoc/button'

import { regions } from '../../defaults/default-config'
import image from '../../assets/icons/sops.png'

import './legal-manager.css'

const setRegion = () => {
    // const { preferences, setPreferences } = useContext(UserContext)

    // setPreferences(preferences => { return {...preferences, region: 'South-East Queensland' }})

}


const LegalManagerComponent = () => {

    const { showLegal, setShowLegal, setModalActive } = useContext(UIContext)
    const { preferences, setPreferences } = useContext(UserContext)

    const handleSave = () => {
        setPreferences(preferences => { return {...preferences, acceptedTerms: {seen: true, version: 1}}})
        setShowLegal(false)
        setModalActive(false)
    }


    useEffect(()=> {
        if (preferences.acceptedTerms === null || !preferences.acceptedTerms) {
            setShowLegal(true)
            setModalActive(true)
        }
    }, [preferences])


    if (!showLegal){ return null }

    if (showLegal){ return(
        <Fragment>
        <div className={'blurbackground'}/>
        <div className={'blockbackground'} onClick={() => {}}>

        <div className={'LegalManagerComponent'}>
        
            
            <div className='welcomeimage'><img  src={image}/></div>
            <p>Welcome to the SOFTOPS Gel Ball App beta!</p>

            

            <h3>In this release</h3>
            <ul className=''>
                <li>News and content feed</li>
                <li>Event listings</li>
                <li>Promoted events</li>
            </ul>
            <p>
            You can check out a detailed overview once you get inside. Let's quickly get some legal stuff out of the way.
            </p>

            <h3>Legal Stuff</h3>
            <p>By proceeding, you agree the following:</p>

            <ul className='legalLinks'>
                <li><a href='/policies/terms' target='_blank'>Terms and Conditions</a></li>
                <li><a href='/policies/disclaimer' target='_blank'>Disclaimer</a></li>
                <li><a href='/policies/privacy' target='_blank'>Privacy Policy</a></li>
            </ul>

            <div className='buttons-container'> 
            <ButtonText className='cta' onClick={function(){handleSave()}}>I accept, let me in!</ButtonText>
            </div>
        
        </div>
        </div>
        </Fragment>

    )}
    

}

export { LegalManagerComponent, setRegion }