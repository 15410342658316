import React, {useEffect, useState} from 'react'

import './lazy-load.css'

// TODO: update to IntersectionObserver wrapper
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

const LazyLoad = ({
    onVisible = () => {},
    onNotVisible = () => {},
    status=false,
    lead = 0,
    noResultMessage = 'That\'s all!',
    ...restProps
}) => {

    const [ visible, setVisible ] = useState(0)

    useEffect(()=> {
        const checkVisible = () => {
            const falseBottom = 300 // how far off the bottom do we consider 'bottom of page'
            const thresh = .8 // what % do we trigger the update?
            const perc = window.pageYOffset / document.body.offsetHeight
            const percWFat = window.pageYOffset / (document.body.offsetHeight - window.innerHeight/2)
            const endPage = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - falseBottom
            

            // console.log('st:', perc >= thresh, perc, 'fat:', percWFat >= thresh, percWFat, 'end:', endPage)

            if ( percWFat >= thresh || endPage
            ) { 
                setVisible(visible => { return visible += 1 })
            }
            else { 
            }

        }

        window.addEventListener('scroll', checkVisible)
        return () => { window.removeEventListener('scroll', checkVisible)}
    }, [])


    useEffect(() => {

        if (visible !== 0) { onVisible(); }
        if (visible === 0) { onNotVisible(); }

    }, [visible])

    return (
        <div id={`lazy-load`} className={`lazy-load ${status ? '' : 'static'}`}>
        { status ? 'Loading' :  noResultMessage }
            
        </div>
    )
}

export {LazyLoad}