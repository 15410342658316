import React, {Fragment, useEffect, useContext} from 'react'
import { Longform } from '../../hoc/views'
import { UIContext } from '../../context/UI'

export const Disclaimer = () => {

    const { setShowNavbar } = useContext(UIContext)

    useEffect(()=> {
        setShowNavbar(false)
    }, )


    const content = <Fragment>
    <h1>Disclaimer</h1>

    <p>Last updated: February 26, 2020</p>
    
    <h1>Interpretation and Definitions</h1>
    <h2>Interpretation</h2>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
    <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    
    <h2>Definitions</h2>
    <p>For the purposes of this Disclaimer:</p>
    <ul>
        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to Frame Butter Pty Ltd T/A Softops</li>
        <li><strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        <li><strong>Website</strong> refers to  ().</li>    
        <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device named SOFTOPS.</li>    
        <li><strong>Service</strong> refers to the Website or the Application or both.</li>
    </ul>
    
    <h1>Disclaimer</h1>
    <p>The information contained on the Service is for general information purposes only.</p>
    <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
    <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>
    <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
    
    
    
    <h1>FTC Affiliate Disclaimer</h1>
    <p>The disclosure that follows is intended to fully comply with the Federal Trade Commission’s policy of the United States that requires to be transparent about any and all affiliate relations the Company may have on the Service.</p>
    <p>You should assume that some of the links are "affiliate links", a link with a special tracking code.</p>
    <p>This means that if You click on an affiliate link and purchase the item, the Company may receive an affiliate commission. This is a legitimate way to monetize and pay for the operation of the Service and the Company gladly reveal its affiliate relationships to You.</p>
    <p>The price of the item is the same whether it is an affiliate link or not. Regardless, the Company  only recommend products or services the Company believe will add value to users.</p>
    <p>Affiliate advertising programs that the Service uses are:</p>
    <ul>
        <li>
              <p>Amazon Services LLC Associates Program</p>
              <p>As an Amazon Associate, I earn from qualifying purchases.</p>
              <p>Frame Butter Pty Ltd T/A Softops is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com or endless.com, MYHABIT.com, SmallParts.com, or AmazonWireless.com.</p>
              <p>Pages on this Service may include affiliate links to Amazon and its affiliate sites on which the owner of this Service, Frame Butter Pty Ltd T/A Softops, will make a referral commission.</p>
        </li>
        <li>
            <p>eBay</p>
        </li>

      </ul>
    
    <h1>External Links Disclaimer</h1>
    <p>The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.</p>
    <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
    
    <h1>Advertising Disclaimer</h1>
    <p>Third party advertisements and links to third party websites may also appear while using the Service. The Company make any representation as to the accuracy or suitability of any of the information contained in those advertisements or websites and does not accept any responsibility or liability for the conduct or content of those advertisements and websites and the offerings made by the third-parties.</p>
    <p>Third party advertisements and links to other websites where goods or services are advertised are not endorsements or recommendations by the Company of the third party sites, goods or services. The Company takes no responsibility for the content of the ads, promises mad, or the quality/reliability of the products or services offered in all advertisements.</p>
    
    <h1>Product Reviews Disclaimer</h1>
    <p>In order to monetize and pay for the operation of the Service, the Company may receive compensation (either monetarily or through free products) for reviews, or endorsement of a particular company, product, or service.</p>
    <p>The Company remains independent and reviews are done based on the Company’s own opinions regardless the compensation that can be received.</p>
    <p>In accordance with the Federal Trade Commission’s policy of the United States, articles which contain a company, product or service review for which a compensation has been received, will include a disclaimer.</p>
    
    <h1>Errors and Omissions Disclaimer</h1>
    <p>The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to insure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.</p>
    <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>
    
    <h1>Fair Use Disclaimer</h1>
    <p>The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.</p>
    <p>The Company believes this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the United States Copyright law.</p>
    <p>If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.</p>
    
    <h1>Views Expressed Disclaimer</h1>
    <p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.</p>
    <p>Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserve the right to delete any comment for any reason whatsoever.</p>
    
    <h1>No Responsibility Disclaimer</h1>
    <p>The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
    <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.</p>
    
    <h1>"Use at Your Own Risk" Disclaimer</h1>
    <p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
    <p>The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
    
    <h2>Contact Us</h2>
    <p>If you have any questions about this Disclaimer, You can contact Us:</p>
    
    <ul>
            <li>By email: info@softops.app</li>
            </ul>
    </Fragment>

    return (
        <Longform content={content} titlebar={'Disclaimer'}/>
    )
}