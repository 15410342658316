import React, {useEffect, useState, useContext, useRef} from 'react'

import { firestoreDB } from '../firebase'
import { AuthContext } from '../context/Auth'
import { UserContext } from '../context/User'


import Moment from 'moment'
import { User } from 'react-feather'

export const DataContext = React.createContext()

export const DataProvider = ({ children }) => {


    // Dependencies
    const { onlineStatus, setOnlineStatus } = useContext(AuthContext)
    const { preferences } = useContext(UserContext)

    const [ready, setReady] = useState(false)
    const [currentDates, setDates] = useState([])

    const queryLimit = 10
    const [ currentDataPage, setDataPage ] = useState(0)
    const [ currentStartAt, setStartAt ] = useState(null)
    const latestStartAt = useRef(currentStartAt)
    const [currentDateFilters, setDateFilters] = useState({ startDate: Moment().startOf('d').toDate(),
                                                            endDate: Moment().startOf('d').add(7, 'days').toDate(),})
    const latestDateFilters = useRef(currentDateFilters)
    const dataStateTemplate = 
        {   data:[],
            status:{
                fetching: false,
                attempted: false,
                outcome: null
            }
        }


    // Data and retrieval state
    const [currentEventData, setEventData]                  = useState(dataStateTemplate)
    const [filteredEventData, setFilteredEventData]         = useState([])
    const [currentPinnedData, setPinnedData]                = useState(dataStateTemplate)
    const [currentArticleData, setArticleData]              = useState(dataStateTemplate)
    // const [currentOperatorData, setOperatorData]            = useState(dataStateTemplate)
    // const [currentLocationData, setLocationData]            = useState(dataStateTemplate)
    // const [currentPriceData, setPriceData]                  = useState(dataStateTemplate)

    const [ queryHistory, setQueryHistory ] = useState([])
    const [ singleHistory, setSingleHistory ] = useState([])
    const [ emptyFetchCount, setEmptyFetchCount ] = useState(0)


    // NEWS ARTICLES Data / articles retrieval
    const [ newsQueryHistory, setNewsQueryHistory ] = useState([])
    const [ singleNewsHistory, setSingleNewsHistory ] = useState([])
    const [ newsEmptyFetchCount, setNewsEmptyFetchCount ] = useState(0)
    const [ currentNewsPage, setNewsPage ] = useState(0)
    const [ currentNewsStartAt, setNewsStartAt ] = useState(null)
    const latestNewsStartAt = useRef(currentNewsStartAt)



    useEffect(() => {
        if(!ready){setReady(true)}
    },[])

    // Listen for 'Events' requests and query Firestore accordingly
    useEffect(() => {

        const getRemoteEventData = (e) => {

            const data = e.detail || null
    
            var getMethod
            var docId
            var ownerId
            var region
            // const following = [] // not yet implemented
    
            var eventsRef

            var startDate = Moment().startOf('d').toDate()
            var startAt = latestStartAt.current
           

            const generateRef = (id) => {return firestoreDB.collection('events-listing').doc(id)}
    
            // Determine what method of data retrieval we want to use
            // may be able to layer the logic in here for date ranges
            if (data) { getMethod = data.method || null }
            process.env.REACT_APP_STAGING === 'dev' && console.log(`%cData request: %cEvents by ${getMethod}`, 'color: orange', 'color: yellow')    

            switch (getMethod) {
                case 'single':
                        docId = data.id
                        eventsRef = generateRef(docId)
                    break;
                case 'more':
                case 'region':
                        region = data.region
                        eventsRef = firestoreDB.collection('events-listing')
                                                .where('date.utcStart', '>=', startDate )
                                                // .where('date.start', '<=', endDate)
                                                .where('location.data.region', '==', region)
                                                .orderBy('date.utcStart')
                                                .startAfter(startAt)
                                                // .endAt(startAt + 40)
                                                .limit(queryLimit)
                        // console.log('Dates:')
                    break;
                case 'following':
                    //  not yet implemented
                    //
                    return
                case 'featured':
                        eventsRef = firestoreDB.collection('events-listing')
                                                .where('date.utcStart', '>=', startDate)
                                                .where('promotion.applies', '==', true)
                                                .orderBy('date.utcStart')
                                                .limit(queryLimit)
                    break;
                case 'byowner':
                        ownerId = data.ownerId
                        eventsRef = firestoreDB.collection('events-listing')
                                                .where('date.start', '>=', startDate )
                                                .where('operator.id', '==', ownerId)
                                                .limit(queryLimit)
                    break;
                default:
                    //  no default behavior
                    break;
            }
            


            if (true // onlineStatus // something is up here.. onlineStatus is being provided as a stale state
                && (!currentEventData.status.fetching && currentEventData.status.outcome !== 'failed') // TODO: need better logic here
               ) {
                    let newStatus = {fetching: true, attempted: true, outcome: null}
    
                    setEventData(currentEventData => {
                            return {data: currentEventData.data, status: newStatus}
                        })
                    
                    eventsRef.get()
                            .then(snapshot => {
                                let newEventData = []
                                if (snapshot.empty || (snapshot.id && !snapshot.exists)) {
                                    switch(getMethod) {
                                        // If we're refreshing after a delete and it was successful, we should get empty result
                                        // todo: update this
                                        case 'single':
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setSingleHistory(singleHistory => { return [...singleHistory, docId]})
                                                setEventData(currentEventData => { return {...currentEventData, status:newStatus} })
                                            break

                                        case 'more':
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setEmptyFetchCount(emptyFetchCount => { return emptyFetchCount+=1 })
                                                setEventData(currentEventData => { return {...currentEventData, status:newStatus} })
                                                setDataPage(currentDataPage => { return currentDataPage += 1})

                                            break


                                        default:
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setEmptyFetchCount(emptyFetchCount => { return emptyFetchCount+=1 })
                                                setEventData(currentEventData => { return {...currentEventData, status:newStatus} })
                                            break  
    
                                    }
                                    process.env.REACT_APP_STAGING === 'dev' && console.log(`%cEvents by ${getMethod} returned %c${0} documents`, 'color: yellow', 'color: inherit')
                                    return
                                }
    
                                newStatus={fetching: false, attempted: true, outcome: 'success'}
                                switch (getMethod) {
                                    case 'single':
                                        
                                        let event = {
                                            id: snapshot.id,
                                            ...snapshot.data() }
                                            setEventData(currentEventData => {
                                                var updatedData = currentEventData.data
                                                var index = updatedData.findIndex(event => {
                                                    return event.id === docId
                                                })
                                                    
                                                if (index >= 0 ) {
                                                    // Document already exists, so update our records
                                                    updatedData[index] = event
                                                } else {
                                                    // Its a new document, so append to our records
                                                    updatedData = [...updatedData, event]
                                                }
    
                                                updatedData.sort(function(a,b){
                                                    // Sort the event data so it displays properly
                                                    const eventA = a.date.start
                                                    const eventB = b.date.start
                                                    let comparison = 0
                                                    if (eventA < eventB) { comparison = -1 } 
                                                        else if ( eventA > eventB ) { comparison = 1 }
                                                    return comparison
                                                })
                                                process.env.REACT_APP_STAGING === 'dev' && console.log('Returned single event')
                                                return { data: updatedData, status: newStatus }
    
                                            })
                                        return
    
                                    // For any result set that is iterable
                                    case 'more':
                                    case 'region':
                                    case 'byowner':
                                            setStartAt(snapshot.docs[snapshot.docs.length-1])
                                            latestStartAt.current = snapshot.docs[snapshot.docs.length-1]
                                    case 'featured':
                                            // console.log('Batch data returned.')
                                            
                                            snapshot.forEach(doc=> {
                                                let event = 
                                                {   id: doc.id,
                                                    ...doc.data()   }
                                                newEventData.push(event)
                                            })
                                            // console.log('setting', snapshot.docs[snapshot.docs.length-1])

                                            setQueryHistory(queryHistory => {return [...queryHistory, {queryType: getMethod, region:data.region} ]})
                                            setEventData(currentEventData => {
                                                var updatedData = currentEventData.data
                                                var numInserts = 0
    
                                                newEventData.map((newEvent) => {
                                                    var index = updatedData.findIndex(event => {
                                                        return event.id === newEvent.id
                                                    })
                                                        
                                                    if (index >= 0 ) {
                                                        // Document already exists, so update our records
                                                        updatedData[index] = newEvent
                                                    } else {
                                                        // Its a new document, so append to our records
                                                        updatedData = [...updatedData, newEvent]
                                                        numInserts++
                                                    }
                                                })

    
                                                updatedData.sort(function(a,b){
                                                    // Sort the event data so it displays properly
                                                    const eventA = a.date.start
                                                    const eventB = b.date.start
                                                    let comparison = 0
                                                    if (eventA < eventB) { comparison = -1 } 
                                                        else if ( eventA > eventB ) { comparison = 1 }
                                                    return comparison
                                                })

                                                process.env.REACT_APP_STAGING === 'dev' && console.log(`%cEvents by ${getMethod} returned %c${snapshot.docs.length} documents, and inserted ${numInserts} new records`, 'color: yellow', 'color: inherit')
                                                process.env.REACT_APP_STAGING === 'dev' && console.log(updatedData)
                                                // console.table(updatedData,
                                                //     ['id','name', 'operator', 'location.data.region']
                                                //     )
                                                return { data: updatedData, status: newStatus }
                                            })
                                        return
    
                                    default:
                                            // Do nothing
                                        return
    
                                }
    
                            })
                            .catch(error => {
                                process.env.REACT_APP_STAGING === 'dev' && console.log('Something went wrong with Firebase\n', error )

                            })
                    } else {
                        // Do not attempt document retrieval
                        process.env.REACT_APP_STAGING === 'dev' && 
                        console.error('Something stopped data request.',
                                    `onlineStatus: ${onlineStatus}`,
                                    `fetchingStatus: ${currentEventData.status.fetching}`,
                                    `outcome: ${currentEventData.status.outcome}` )
                    } 
        }

        window.addEventListener('getEventData', (e) => { getRemoteEventData(e) })
        return () => window.removeEventListener('getEventData', (e) => { getRemoteEventData(e) });
       
    }, [])

    // Update filtered Event Data
    useEffect(() => {
        const newFilteredEventData = currentEventData.data.filter(event => {
            return event.location.data.region == preferences.region
        })

        setFilteredEventData(filteredEventData => {
            return ([...newFilteredEventData])
        })

    }, [currentEventData, preferences])

    // Listen for 'Articles' requests and query Firestore accordingly
    useEffect(() => {

        const getRemoteArticleData = (e) => {

            const data = e.detail || null
            var articleLimit = 5
            var getMethod
            var docId
            // const following = [] // not yet implemented

            var startDate = Moment().startOf('d').add(1,'d').toDate()
    
            var articleRef
            var startAt = latestNewsStartAt.current
            console.log(startAt)
   
            // Determine what method of data retrieval we want to use
            // may be able to layer the logic in here for date ranges
            if (data) { getMethod = data.method || null }
            process.env.REACT_APP_STAGING === 'dev' && console.log(`%cData request: %cArticles by ${getMethod}`, 'color: orange', 'color: yellow')    
            switch (getMethod) {
                case 'single':
                        docId = data.id
                        articleRef = firestoreDB.collection('article-listing').doc(docId)
                    break;
                case 'more':
                        // region = data.region
                        if (startAt !== null) {
                        articleRef = firestoreDB.collection('article-listing')
                                                 .where('datePublished', '<=', startDate )
                                                // .where('date.start', '<=', endDate)
                                                // .where('location.data.region', '==', region)
                                                .orderBy('datePublished', 'desc')
                                                .startAfter(startAt) 
                                                // .endAt(startAt + 40)
                                                .limit(articleLimit)
                        } else {
                            articleRef = firestoreDB.collection('article-listing')
                            .where('datePublished', '<=', startDate )
                           // .where('date.start', '<=', endDate)
                           // .where('location.data.region', '==', region)
                           .orderBy('datePublished', 'desc')
                        //    .startAfter(startAt) 
                           // .endAt(startAt + 40)
                           .limit(articleLimit)
                        }
                    break;
                case 'tag':
                    // Get events by a particular tag, e.g. 'video', 'tv'
                    break;
                case 'following':
                    //  not yet implemented
                    //
                    return
                case 'featured':
                        // eventsRef = firestoreDB.collection('article-listing')
                        //                         .where('date.utcStart', '>=', startDate)
                        //                         .where('promotion.applies', '==', true)
                        //                         .orderBy('date.utcStart')
                    break;
                default:
                    //  no default behavior
                    break;
            }
            


            if (true // onlineStatus // something is up here.. onlineStatus is being provided as a stale state
                && (!currentArticleData.status.fetching && currentArticleData.status.outcome !== 'failed') // TODO: need better logic here
               ) {
                    let newStatus = {fetching: true, attempted: true, outcome: null}
    
                    setArticleData(currentArticleData => {
                            return {data: currentArticleData.data, status: newStatus}
                        })
                    
                    articleRef.get()
                            .then(snapshot => {
                                let newArticleData = []
                                if (snapshot.empty || (snapshot.id && !snapshot.exists)) {
                                    switch(getMethod) {
                                        // If we're refreshing after a delete and it was successful, we should get empty result
                                        // todo: update this
                                        case 'single':
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setSingleNewsHistory(singleNewsHistory => { return [...singleNewsHistory, docId]})
                                                setArticleData(currentArticleData => { return {...currentArticleData, status:newStatus} })
                                            break

                                        case 'more':
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setNewsEmptyFetchCount(newsEmptyFetchCount => { return newsEmptyFetchCount+=1 })
                                                setArticleData(currentArticleData => { return {...currentArticleData, status:newStatus} })
                                                setNewsPage(currentNewsPage => { return currentNewsPage += 1})

                                            break


                                        default:
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setNewsEmptyFetchCount(newsEmptyFetchCount => { return newsEmptyFetchCount+=1 })
                                                setArticleData(currentArticleData => { return {...currentArticleData, status:newStatus} })
                                            break  
    
                                    }
                                    process.env.REACT_APP_STAGING === 'dev' && console.log(`%cArticles by ${getMethod} returned %c${0} documents`, 'color: yellow', 'color: inherit')
                                    return
                                }
    
                                newStatus={fetching: false, attempted: true, outcome: 'success'}
                                switch (getMethod) {
                                    case 'single':
                                        let article = {
                                            id: snapshot.id,
                                            ...snapshot.data() }
                                            setArticleData(currentArticleData => {
                                                var updatedData = currentArticleData.data
                                                var index = updatedData.findIndex(article => {
                                                    return article.id === docId
                                                })
                                                    
                                                if (index >= 0 ) {
                                                    // Document already exists, so update our records
                                                    updatedData[index] = article
                                                } else {
                                                    // Its a new document, so append to our records
                                                    updatedData = [...updatedData, article]
                                                }
    
                                                updatedData.sort(function(a,b){
                                                    // Sort the event data so it displays properly
                                                    const articleA = a.datePublished
                                                    const articleB = b.datePublished
                                                    let comparison = 0
                                                    if (articleA > articleB) { comparison = -1 } 
                                                        else if ( articleA < articleB ) { comparison = 1 }
                                                    return comparison
                                                })
                                                process.env.REACT_APP_STAGING === 'dev' && console.log('Returned single article')
                                                return { data: updatedData, status: newStatus }
    
                                            })
                                        return
    
                                    // For any result set that is iterable
                                    case 'more':
                                    case 'region':
                                    case 'byowner':
                                            setNewsStartAt(snapshot.docs[snapshot.docs.length-1])
                                            latestNewsStartAt.current = snapshot.docs[snapshot.docs.length-1]
                                    case 'featured':
                                            // console.log('Batch data returned.')
                                            
                                            snapshot.forEach(doc=> {
                                                let article = 
                                                {   id: doc.id,
                                                    ...doc.data()   }
                                                newArticleData.push(article)
                                            })
                                            // console.log('setting', snapshot.docs[snapshot.docs.length-1])

                                            setNewsQueryHistory(newsQueryHistory => {return [...newsQueryHistory, {queryType: getMethod} ]})
                                            setArticleData(currentArticleData => {
                                                var updatedData = currentArticleData.data
                                                var numInserts = 0
    
                                                newArticleData.map((newArticle) => {
                                                    var index = updatedData.findIndex(article => {
                                                        return article.id === newArticle.id
                                                    })
                                                        
                                                    if (index >= 0 ) {
                                                        // Document already exists, so update our records
                                                        updatedData[index] = newArticle
                                                    } else {
                                                        // Its a new document, so append to our records
                                                        updatedData = [...updatedData, newArticle]
                                                        numInserts++
                                                    }
                                                })

    
                                                updatedData.sort(function(a,b){
                                                    // Sort the event data so it displays properly
                                                    const articleA = a.datePublished
                                                    const articleB = b.datePublished
                                                    let comparison = 0
                                                    if (articleA > articleB) { comparison = -1 } 
                                                        else if ( articleA < articleB ) { comparison = 1 }
                                                    return comparison
                                                })

                                                process.env.REACT_APP_STAGING === 'dev' && console.log(`%cArticles by ${getMethod} returned %c${snapshot.docs.length} documents, and inserted ${numInserts} new records`, 'color: yellow', 'color: inherit')
                                                process.env.REACT_APP_STAGING === 'dev' && console.log(updatedData)
                                                // console.table(updatedData,
                                                //     ['id','name', 'operator', 'location.data.region']
                                                //     )
                                                return { data: updatedData, status: newStatus }
                                            })
                                        return
    
                                    default:
                                            // Do nothing
                                        return
    
                                }
    
                            })
                            .catch(error => {
                                process.env.REACT_APP_STAGING === 'dev' && 
                                console.log('Something went wrong with Firebase\n', error )

                            })
                    } else {
                        // Do not attempt document retrieval
                        process.env.REACT_APP_STAGING === 'dev' && 
                        console.error('Something stopped news request.',
                                    `onlineStatus: ${onlineStatus}`,
                                    `fetchingStatus: ${currentEventData.status.fetching}`,
                                    `outcome: ${currentEventData.status.outcome}` )
                    } 
        }

        window.addEventListener('getArticleData', (e) => { getRemoteArticleData(e) })
        return () => window.removeEventListener('getArticleData', (e) => { getRemoteArticleData(e) });
       
    }, [])


    useEffect(() => {

        const getRemotePinnedData = (e) => {

            const data = e.detail || null
            var articleLimit = 5
            var getMethod
            var docId
            // const following = [] // not yet implemented

            var startDate = Moment().startOf('d').add(1,'d').toDate()
    
            var articleRef
            var startAt = latestNewsStartAt.current
            console.log(startAt)
   
            // Determine what method of data retrieval we want to use
            // may be able to layer the logic in here for date ranges
            if (data) { getMethod = data.method || null }
            process.env.REACT_APP_STAGING === 'dev' && console.log(`%cData request: %cPinned by ${getMethod}`, 'color: orange', 'color: yellow')    
            switch (getMethod) {
                case 'more':
                        articleRef = firestoreDB.collection('article-pinned')
                                                .limit(articleLimit)
                    break;
                default:
                    //  no default behavior
                    break;
            }
            


            if (true // onlineStatus // something is up here.. onlineStatus is being provided as a stale state
                && (!currentPinnedData.status.fetching && currentPinnedData.status.outcome !== 'failed' && currentPinnedData.data.length === 0) // TODO: need better logic here
               ) {
                    let newStatus = {fetching: true, attempted: true, outcome: null}
    
                    setPinnedData(currentPinnedData => {
                            return {data: currentPinnedData.data, status: newStatus}
                        })
                    
                    articleRef.get()
                            .then(snapshot => {
                                let newPinnedData = []
                                if (snapshot.empty || (snapshot.id && !snapshot.exists)) {
                                    switch(getMethod) {
                                        case 'more':
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setPinnedData(currentPinnedData => { return {...currentPinnedData, status:newStatus} })
                                            break


                                        default:
                                                newStatus = {fetching: false, attempted: true, outcome: 'empty'}
                                                setPinnedData(currentPinnedData => { return {...currentPinnedData, status:newStatus} })
                                            break  
    
                                    }
                                    process.env.REACT_APP_STAGING === 'dev' && console.log(`%cPinned by ${getMethod} returned %c${0} documents`, 'color: yellow', 'color: inherit')
                                    return
                                }
    
                                newStatus={fetching: false, attempted: true, outcome: 'success'}
                                switch (getMethod) {
                                    case 'more':
                                            snapshot.forEach(doc=> {
                                                let article = 
                                                {   id: doc.id,
                                                    ...doc.data()   }
                                                newPinnedData.push(article)
                                            })

                                            setPinnedData(currentPinnedData => {
                                                var updatedData = currentPinnedData.data
                                                var numInserts = 0
    
                                                newPinnedData.map((newArticle) => {
                                                    var index = updatedData.findIndex(article => {
                                                        return article.id === newArticle.id
                                                    })
                                                        
                                                    if (index >= 0 ) {
                                                        // Document already exists, so update our records
                                                        updatedData[index] = newArticle
                                                    } else {
                                                        // Its a new document, so append to our records
                                                        updatedData = [...updatedData, newArticle]
                                                        numInserts++
                                                    }
                                                })

    
                                                updatedData.sort(function(a,b){
                                                    // Sort the event data so it displays properly
                                                    const articleA = a.datePublished
                                                    const articleB = b.datePublished
                                                    let comparison = 0
                                                    if (articleA > articleB) { comparison = -1 } 
                                                        else if ( articleA < articleB ) { comparison = 1 }
                                                    return comparison
                                                })

                                                process.env.REACT_APP_STAGING === 'dev' && console.log(`%cPinned by ${getMethod} returned %c${snapshot.docs.length} documents, and inserted ${numInserts} new records`, 'color: yellow', 'color: inherit')
                                                process.env.REACT_APP_STAGING === 'dev' && console.log(updatedData)
                                                // console.table(updatedData,
                                                //     ['id','name', 'operator', 'location.data.region']
                                                //     )
                                                return { data: updatedData, status: newStatus }
                                            })
                                        return
    
                                    default:
                                            // Do nothing
                                        return
    
                                }
    
                            })
                            .catch(error => {
                                process.env.REACT_APP_STAGING === 'dev' && 
                                console.log('Something went wrong with Firebase\n', error )

                            })
                    } else {
                        // Do not attempt document retrieval
                        process.env.REACT_APP_STAGING === 'dev' && 
                        console.error('Something stopped pinned items request request.',
                                    `onlineStatus: ${onlineStatus}`,
                                    `fetchingStatus: ${currentEventData.status.fetching}`,
                                    `outcome: ${currentEventData.status.outcome}` )
                    } 
        }

        window.addEventListener('getPinnedArticles', (e) => { getRemotePinnedData(e) })
        return () => window.removeEventListener('getPinnedArticles', (e) => { getRemotePinnedData(e) });
       
    }, [])

    return (
        <DataContext.Provider
        value={{
            currentDates, ready,
            
            // Event Data
            queryHistory, setQueryHistory, singleHistory,
            currentEventData, filteredEventData, 
            currentDataPage, setDataPage, 
            currentStartAt, setStartAt, latestStartAt,
            currentArticleData,
            currentDateFilters, setDateFilters, latestDateFilters,
            emptyFetchCount, setEmptyFetchCount,


            // News Data

            currentPinnedData,
            newsQueryHistory, setNewsQueryHistory,
            singleNewsHistory, setSingleNewsHistory,
            newsEmptyFetchCount, setNewsEmptyFetchCount,
            currentNewsPage, setNewsPage,
            currentNewsStartAt, setNewsStartAt,
            latestNewsStartAt


        }}
        >
        {children}
        </DataContext.Provider>
            
    )

}

