import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import {firebaseConfig} from './config'
// import 'firebase/auth'


// implement copy function https://blog.cloudboost.io/copy-export-a-cloud-firestore-database-388cde99259b

// const firebaseConfig = {
//     apiKey: "AIzaSyDVHJF3X1-cRq76lH_NXrmulbWNO4ExH4A",
//     authDomain: "softops-app.firebaseapp.com",
//     databaseURL: "https://softops-app.firebaseio.com",
//     projectId: "softops-app",
//     storageBucket: "softops-app.appspot.com",
//     messagingSenderId: "536068959972",
//     appId: "1:536068959972:web:1d66596e93d852ff",
//     measurementId: "G-P6K4SJWK50"
//   };

firebase.initializeApp(firebaseConfig)
const firebaseAnalytics = firebase.analytics();
const firestoreDB = firebase.firestore();


// firestoreDB.enablePersistence()
//   .catch(function(err) {
//     if (err.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//     } else if (err.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//     }
//   });
  //

export {
    firebase,
    firestoreDB,
    firebaseAnalytics

}

