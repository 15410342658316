import React from 'react'
import Moment from 'moment'

import '../hoc/time-display.css'

const TimeDisplay = ({
    size = '',
    timeStart = null,
    timeEnd = null,
    ...restProps
}) =>{

    /*
    TODO:
    - Some logic to convert time and timezones
    - Some logic to handle styling
    - Some logic to handle positioning

    */

    const fontSize = size // large, small - links to css
    const displayTimeStart = timeStart ? Moment(timeStart, 'H:mm').format('h:mmA') : ''
    const displayTimeEnd = timeEnd ? Moment(timeEnd, 'H:mm').format('h:mmA') : ''

    return (
        <div 
            className={`font-technical light ${fontSize}`} 
            >

            <span className='accent'>{displayTimeStart}</span>
            { timeEnd ? <i className='ri-arrow-right-line TimeDisplayArrow'/> : null }
            {displayTimeEnd}

        </div>
    )

}


export default TimeDisplay

