import React, {useContext, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

import ViewBar from '../../hoc/view-bar'
import styles from './filter-view.css'

import { DataContext } from '../../context/Data'
import { UserContext } from '../../context/User'
// TODO: include date context

import { firebaseAnalytics } from '../../firebase'



const FilterView = (props) => {

    const { currentFilters, setFilters } = useContext( DataContext )
    // const { setFilters } = useContext( DataContext )
    const { preferences } = useContext( UserContext)
  
    const viewList = [
        // { label: 'Featured', link:'featured', enabled: true}, 
        { label: `All ${preferences.region}`, link: 'all', enabled: true},
        // { label: 'Liked', link: 'following', enabled: false}
    ]

    // console.log(props)

    const updateFilters = (e) => {
        // Configures a new filter object, and passes to setFilters in DataContext
        // This triggers a requery to Firebase and subsequent rerenders

        // TODO: Make this dynamic
        const filters = {
            startDate: currentFilters.startDate,
            region: e.target.value
        }
        setFilters(filters)
    }

    useEffect(() => {
        // Onload, send an analytics event
        firebaseAnalytics.logEvent('view_list', {region: preferences.region})
    
    }, [])


    return(
        <ViewBar 
            type=''
            className='view-options FilterViewBar'>
            <ul className='FilterViewList'>
                {viewList.map(view => (
                            <li 
                               
                                key={view.link} 
                                className='FilterViewListItem'>
                                
                                <NavLink 

                                    className='FilterViewOption' 
                                    activeClassName='chosen'
                                    isActive={(match, location) => {
                                        // Check if the path+query matches to flag active
                                        if (`${location.pathname}${location.search}` === `/events?view=${view.link}`) {
                                          return true;
                                        }
                                        return false;
                                    }}
                                    to={`/events?view=${view.link}`}>
                                    {view.label}
                                </NavLink>
                            </li>
                    ))
                }
            </ul>                        
        </ViewBar>


    )
}

export default FilterView