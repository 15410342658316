import React from 'react'
import { Redirect } from 'react-router-dom'



const RedirectDefault = (props) => {


    return(
         <Redirect to={ {pathname: '/news'} } />
    )



}

export default RedirectDefault