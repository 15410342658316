import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

import {AuthProvider} from './context/Auth'
import {UIProvider} from './context/UI' 


ReactDOM.render(

    <AuthProvider>

        <UIProvider>
          <App /> 
        </UIProvider>
    </AuthProvider>

    ,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();


