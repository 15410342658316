import React, {useEffect, useState} from 'react'
// import 'firebase/auth'



export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [ currentUser, setCurrentUser] = useState(null)
    const [ onlineStatus, setOnlineStatus ] = useState(true)

    useEffect(() => {
        // firebase.auth().onAuthStateChanged(setCurrentUser)
    }, [])

    const dataEnabled = true

    // Temp solution - used to load the initial dataset by updating the onlineStatus 
    // useEffect(()=> {
    //     function docReady(fn) {
    //         if (dataEnabled){
    //         // see if DOM is already available
    //             if (document.readyState === "complete" || document.readyState === "interactive") {
    //                 // call on next available tick
    //                 setTimeout(fn, 1);
    //                 if (!onlineStatus){setOnlineStatus(true)}
    //                 // console.log('Online, status:', onlineStatus)
    //             } else {
    //                 document.addEventListener("DOMContentLoaded", fn);
    //             }
    //         }
    //       } 
      
    //     docReady()
    // },[])


    return (
        <AuthContext.Provider
        value={{
            currentUser, onlineStatus, setOnlineStatus
        }}
        >
        {children}
        </AuthContext.Provider>
            
    )

}