import React, { useState } from 'react'
import queryString from 'query-string'
import { Redirect } from 'react-router'

// Components
import getEventTagsList from '../../hoc/getEventTagsList'
import TagListDisplay from '../../hoc/tag-list-display'
import ViewBar from '../../hoc/view-bar'
import UIVerified from '../../hoc/ui-verified'
import { ButtonText } from '../../hoc/button'

import { EventBackground } from './event-background'

import './event-view-header.css'

// content


const EventViewHeader = ({
    className = '',
    eventData = {},
    hasBackground = true,
    promoted = false,
    ref = false,
    ...restProps
}) =>{

    const [ shouldRedirect, setShouldRedirect ] = useState(false)

    if (shouldRedirect){
        return (
            <Redirect push to='/events?view=featured'/>
        )
    }

    const operator = eventData.operator

    
    return (
        <div className= {`EventViewHeaderContainer ${className}`}>

            { hasBackground ?  
                <EventBackground promoted={promoted}/>
                : null }

            <div className='EventViewHeaderContent'>
                <div className='EventViewSecondaryDetails'>
                    <TagListDisplay
                        className='EventViewHeaderTags'
                        displayMode={'list'}
                        tags={getEventTagsList(eventData)} 
                        />
                    <div className='EventViewSeriesName'>{eventData.series.name || null}</div>
                </div>


                <h1 id='top' className= 'EventViewTitle'>{eventData.name}</h1>
                
                <ButtonText 
                    icon='ri-arrow-left-line'
                    className={`EventViewButtonBack ${promoted ? 'promoted' : ''}`}
                    onClick={ // Conditionally change the back button function
                        ref ? 
                        function(){ setShouldRedirect(true)} :
                        function(){ window.history.back()}
                        }
                    >{ref ? 'More events' : 'Back'}
                </ButtonText>

       
                <ViewBar
                    type='transparent'
                    className='EventViewBar'>
                    <div 
                        className='primary medium-w button-text nav-links nav-links-left EventViewBarOperator'>
                        <div className='EventViewBarOperatorName'>{operator.name}</div>
                        { operator.data.verified.status ? <UIVerified className='EventViewBarVerified'/> : null }
                        
                        
                    </div>
                    {
                    //    <div className='button-link nav-links nav-links-right'>View Profile</div>
                    }
                </ViewBar>
            </div>





        </div>
    )

}


export default EventViewHeader

