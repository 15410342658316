import React from 'react'

import styles from '../hoc/view-bar.css'

const ViewBar = (props) =>{


    const className = props.className !== undefined ? props.className : null
    const type = props.type != '' ? props.type : ''

    return (
            <div
                className = {`ViewBarContainer ${type} ${className}`}>

                <div className='ViewBarButtonContainer'>
                    {props.children}
                </div>

            </div>
    )

}


export default ViewBar

