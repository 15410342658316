export const defaultDisclaimer = () => {
    return(
        'None of the authors, contributors, administrators, or anyone else connected with SOFTOPS, in any way whatsoever, can be responsible for your use of the information contained in or linked from these web pages. Please take all steps necessary to ascertain that information you receive from SOFTOPS is correct and has been verified. Double-check all information with event organisers and operators.'
    )
}


export const dummyMarkdown = () => {
    return (

        '![image](/img/articles/review-1.png)' +
        "\n G'day all, ok first thing this event is for experienced players if ya haven't played at one of our events before sorry but ya going to have to sit this one out.\n " +
        '### A few things \n' +
        '- No Eftpos available at this event so please bring cash.\n '+
        '- No refs for this event so everyone is to be expected to play fairly\n '+
        '- A few more well known game modes just to brake things up a bit over the event\n\n '+
        '\n\n We want players that are opened minded and are willing to try out new game modes and help us with their input and improvement of the new game modes.'+
        '\n Everyone is welcome that has played at least at one of our events before but ya must be over the age of 14 coz this event has NO bang bang 2 meter ruling so be aware there maybe a possibility you may get shot at point blank\n\n'
                

    )
}