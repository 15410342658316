import React from 'react'
import UIVerified from '../ui-verified'
// Styles
import './operator-logo.css'


// Icons
import icon from '../../assets/fields/si-p-hardcoreblasters.svg'


const OperatorLogo = ({
        logoType = 'default',
        logoContent = 'ABC',
        logoText = null,
        logoUrl = null,
        className,
        verified = false
    }) => {
     

    return (
        <div className={`OperatorLogoContainer ${className}`}>
            <div className='OperatorLogoContent'>
                {   logoType === 'default' ?
                    <div className='OperatorLogoIcon text'>{logoText}</div>
                    : <img src={logoUrl} className='OperatorLogoIcon image'/>
                }
                

                
            </div>
            {verified ? <UIVerified className='OperatorLogoVerified'/> : null}
        </div>
    )
}

export {
    OperatorLogo
}

